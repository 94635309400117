
import { IonToolbar, IonTitle, IonHeader, IonButtons, IonIcon } from '@ionic/vue';
import { computed, defineComponent } from 'vue';
import { Routes } from '.././router';
import { useRouter } from 'vue-router';
import { useCoursesStore } from '.././store/courses';
import { arrowBackOutline } from 'ionicons/icons';

export default defineComponent({
    name: 'BasePageLayout',
    components: {
        IonToolbar,
        IonTitle,
        IonHeader,
        IonButtons,
        IonIcon,
    },
    props: {
        backButtonFunction: {
            type: Function,
            default: undefined,
            required: false,
        },
    },
    setup() {
        const router = useRouter();

        const pageTitle = computed(() => {
            if (
                router.currentRoute.value.name === Routes.PRACTICE_AREA_COURSE_PLAN ||
                router.currentRoute.value.name === Routes.PRACTICE_AREA_COURSE_PLAN_ARCHIVE ||
                router.currentRoute.value.name === Routes.PRACTICE_AREA_COURSE_PLAN_DASHBOARD
            ) {
                const courseId = Number(router.currentRoute.value.params.id);
                const coursesStore = useCoursesStore();
                const course = coursesStore.getFullCourseDataById(courseId);
                if (course.value?.courseName) {
                    return course.value.courseName;
                }
            }
            return router.currentRoute.value.meta.title || 'Generic';
        });

        const fullscreenView = computed(() => {
            // NOTE: can be used to hide header/footer. But causes ugly jumps
            return !!router.currentRoute.value.meta.fullscreen;
            // return false;
        });

        return {
            pageTitle,
            fullscreenView,
            arrowBackOutline,
        };
    },
});
