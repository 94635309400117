
import {
    IonInput,
    IonRow,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonButton,
    toastController,
    loadingController,
    onIonViewDidEnter,
} from '@ionic/vue';
import { informationCircle, checkmarkCircle, arrowForwardCircle } from 'ionicons/icons';
import { defineComponent, Ref, ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { Routes } from '@/router';
import { getApiBaseUrl, getAxiosRequestInstance } from '@/services/Request.service';
import { getReadableServerMessage } from '@/services/Translations.service';
import BasePageLayout from '@/components/BasePageLayout.vue';
import { useUserStore } from '@/store/user';

export default defineComponent({
    name: 'RegisterPage',
    components: {
        IonInput,
        IonItem,
        IonIcon,
        IonRow,
        IonLabel,
        IonPage,
        IonButton,
        BasePageLayout,
    },
    setup() {
        const router = useRouter();
        const password: Ref<string> = ref('');
        const passwordConfirmation: Ref<string> = ref('');
        const code: Ref<string> = ref('');
        const userStore = useUserStore();

        const navigateLogin = () => {
            router.push({ name: Routes.LOGIN });
        };

        const setNewPasswort = async () => {
            if (
                password.value === '' ||
                passwordConfirmation.value === '' ||
                password.value != passwordConfirmation.value
            ) {
                showMessage({ message: 'Bitte zweimal das gleiche Passwort eintragen' });
                return;
            }

            const loading = await loadingController.create({
                message: 'Einen Moment noch!',
            });
            await loading.present();
            await new Promise((resolve) => setTimeout(resolve, 300));

            try {
                // if we come from a rest email link we will use the code to perfom the password change
                if (code.value) {
                    await axios.post(`${getApiBaseUrl()}/auth/reset-password`, {
                        password: password.value,
                        passwordConfirmation: passwordConfirmation.value,
                        code: code.value,
                    });

                    navigateLogin();

                    // inform user
                    showMessage({
                        header: 'Passwort wurde geändert.',
                        message: 'Logge dich jetzt mit deinem neuen Passwort ein.',
                        icon: checkmarkCircle,
                    });
                    return;
                }

                // else we just update the users password
                const axiosRequestInstance = getAxiosRequestInstance();
                await axiosRequestInstance.put('/update_me_base_data', {
                    password: password.value,
                });
                // inform user
                showMessage({
                    message: 'Dein Passwort wurde erfolgreich geändert',
                    icon: checkmarkCircle,
                });
                router.push({ name: Routes.PROFILE });
            } catch (error: any) {
                console.error(error);
                if (!error.response) {
                    showMessage({ message: 'Unbekannter Fehler' });
                    return;
                }
                const serverMessage = error.response.data.error.message;
                showMessage({
                    message: getReadableServerMessage(serverMessage),
                    duration: 0,
                });
                return;
            } finally {
                await loading.dismiss();
            }
        };

        const showMessage = async (overrides: any) => {
            const toast = await toastController.create({
                message: 'Bitte Daten prüfen',
                icon: informationCircle,
                position: 'top',
                duration: 7000,
                buttons: [
                    {
                        text: 'OK',
                        role: 'cancel',
                    },
                ],
                ...overrides,
            });
            await toast.present();
        };

        onIonViewDidEnter(() => {
            password.value = '';
            passwordConfirmation.value = '';

            const paramCode = router.currentRoute.value.query.code;
            if (!paramCode && !userStore.isAuthenticated) {
                navigateLogin();
                return;
            }
            if (paramCode) {
                code.value = String(paramCode);
            }
        });

        return {
            password,
            passwordConfirmation,
            arrowForwardCircle,
            navigateLogin,
            setNewPasswort,
            isAuthenticated: userStore.isAuthenticated,
        };
    },
});
