import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { fullscreen: true }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_refresher, {
        slot: "fixed",
        onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doRefresh($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher_content)
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        id: "container",
        class: _normalizeClass({ 'content-fullscreen': _ctx.contentFullscreen })
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ]),
    _: 3
  }))
}