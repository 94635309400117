
import { useUserStore } from '@/store/user';
import { computed, defineComponent, ref } from 'vue';
import BasePageLayout from '@/components/BasePageLayout.vue';
import BaseHeader from '@/components/BaseHeader.vue';
import CourseProgress from '@/components/CourseProgress.vue';
import CourseDateState from '@/components/CourseDateState.vue';
import { useRouter } from 'vue-router';
import { Routes } from '@/router';
import {
    IonCard,
    IonRow,
    IonIcon,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonPage,
    onIonViewDidEnter,
    IonBadge,
    IonModal,
    IonContent,
    IonHeader,
    loadingController,
    toastController,
    IonItem,
    IonLabel,
    IonCheckbox,
    alertController,
    IonButton,
} from '@ionic/vue';

import {
    closeOutline,
    addCircleOutline,
    checkmarkOutline,
    barChartOutline,
    calendarOutline,
    informationCircleOutline,
    informationCircle,
    clipboardOutline,
    ribbonOutline,
    lockClosedOutline,
    lockOpenOutline,
    trashOutline,
} from 'ionicons/icons';
import { CourseModelFullData, useCoursesStore } from '@/store/courses';
import { getAssetPath } from '@/services/Request.service';
import { usePaymentStore } from '@/store/payment';
import { useCourseData } from '@/store/courses/courseData';
import { convertReadableIsoDurationString } from '@/services/Time.service';
import { getReadableServerMessage } from '@/services/Translations.service';
import { PurchasesPackage } from 'cordova-plugin-purchases/www/plugin';
import { useCourseTrials } from '@/store/courses/courseTrials';
import { isNativePlatform as getIsNativePlatform } from '@/services/Standalone.service';

export default defineComponent({
    name: 'PracticeAreaPage',
    components: {
        BasePageLayout,
        IonIcon,
        IonRow,
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonCardTitle,
        IonCardSubtitle,
        CourseProgress,
        BaseHeader,
        IonPage,
        IonBadge,
        CourseDateState,
        IonModal,
        IonContent,
        IonHeader,
        IonItem,
        IonLabel,
        IonCheckbox,
        IonButton,
    },
    setup() {
        const router = useRouter();
        const coursesStore = useCoursesStore();
        const userStore = useUserStore();
        const user = computed(() => userStore.user);
        //const courses = computed(() => coursesStore.getCourses);
        const coursesFullData = computed(() => coursesStore.getCoursesFullData);
        const showBuyModal = ref(false);
        const courseToBuy = ref<null | CourseModelFullData>(null);
        const paymentStore = usePaymentStore();
        const courseData = useCourseData();
        const courseTrialsStore = useCourseTrials();
        const pkgSelected = ref(null);
        const isNativePlatform = getIsNativePlatform();

        const currentOfferingToShow = computed(() => {
            if (!courseToBuy.value) {
                return null;
            }

            const currentCourseData = courseData.getCourseDataSynchron(courseToBuy.value.courseId);
            const courseOffering = paymentStore.availablePackages.find(
                (item) => item.identifier === currentCourseData.value?.data.attributes.revenuecatOffers
            );

            return {
                course: currentCourseData.value,
                offerings: courseOffering?.availablePackages,
            };
        });

        const doHideBuyModal = () => {
            showBuyModal.value = false;
            courseToBuy.value = null;
        };

        const purchasePackage = async (pkg: PurchasesPackage, event: any) => {
            pkgSelected.value = event.currentTarget === pkgSelected.value ? null : event.currentTarget;
            const loading = await loadingController.create({
                message: 'Warten auf Rückmeldung zum Kauf des Kurses...',
            });

            const courseId = currentOfferingToShow.value?.course?.data.id;
            let success = false;

            try {
                await loading.present();
                await paymentStore.purchasePackage(pkg);
                success = true;
            } catch (error: any) {
                const toast = await toastController.create({
                    header: 'Der Kauf war nicht erfolgreich',
                    message: getReadableServerMessage(error?.message || 'unbekannter Fehler'),
                    icon: informationCircle,
                    position: 'top',
                    duration: 7000,
                    buttons: [
                        {
                            text: 'OK',
                            role: 'cancel',
                        },
                    ],
                });
                await toast.present();
            } finally {
                doHideBuyModal();
                loading.dismiss();

                // redirect user to course page after purchase
                await new Promise((resolve) => setTimeout(resolve, 300));
                if (success && courseId) {
                    goToCourse(courseId);
                }
            }
        };

        const currencyCodeMapping = (code: string) => {
            if (code === 'EUR') {
                return '€';
            }
            return code;
        };

        const requestTrial = async (courseId: number) => {
            const loading = await loadingController.create({
                message: 'Der kostenloser Testzeitraum wird gerade gestartet...',
            });
            await loading.present();

            try {
                const res = await courseTrialsStore.requestCourseTrial(courseId);

                if (res) {
                    const toast = await toastController.create({
                        header: 'Testzeitraum gestartet',
                        message: res ? res.durationLabel : '',
                        icon: informationCircle,
                        position: 'top',
                        duration: 10000,
                        buttons: [
                            {
                                text: 'OK',
                                role: 'cancel',
                            },
                        ],
                    });
                    await toast.present();
                } else {
                    throw new Error();
                }
            } catch (error: any) {
                const toast = await toastController.create({
                    header: 'Fehler',
                    message: 'Der kostenlose Testzeitraum konnte nicht gestartet werden.',
                    icon: informationCircle,
                    position: 'top',
                    duration: 50000,
                    buttons: [
                        {
                            text: 'OK',
                            role: 'cancel',
                        },
                    ],
                });
                await toast.present();
                throw error;
            } finally {
                loading.dismiss();
            }
        };

        const presentBuyModal = async (course: CourseModelFullData) => {
            courseToBuy.value = course;
            showBuyModal.value = true;
        };

        const cancelCourse = async (course: CourseModelFullData) => {
            const alert = await alertController.create({
                header: 'Bist du sicher?',
                message:
                    'Möchtest du den Kurs wirklich entfernen? Dein Fortschritt in diesem Kurs geht damit verloren.',
                buttons: [
                    {
                        text: 'Doch weitermachen',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Jetzt beenden',
                        id: 'confirm-button',
                        handler: async () => {
                            if (!course) {
                                return;
                            }
                            const loading = await loadingController.create({
                                message: 'gleich gehts weiter...',
                            });
                            await loading.present();
                            await new Promise((resolve) => setTimeout(resolve, 300));
                            try {
                                coursesStore.cancelCourse(course.courseId);
                                await router.push({ name: Routes.PRACTICE_AREA });
                            } catch (error) {
                                console.error('hups', error);
                            } finally {
                                loading.dismiss();
                            }
                        },
                    },
                ],
            });
            alert.present();
        };

        const goToCourse = async (id: number) => {
            const course = coursesStore.getFullCourseDataById(id);

            if (!course.value) {
                return;
            }

            // user has currently no access!
            if (!course.value?.userCanUseCourse) {
                if (userStore.isAnonymousTestUser) {
                    // user needs to complete account here
                    router.push({ name: Routes.PROFILE });

                    const toast = await toastController.create({
                        header: 'Testaccount',
                        message: 'Du musst zunächst deinen Account vervollständigen um Premium-Kurse kaufen zu können.',
                        icon: informationCircle,
                        position: 'top',
                        buttons: [
                            {
                                text: 'OK',
                                role: 'cancel',
                            },
                        ],
                    });
                    await toast.present();

                    // exit here.
                    return;
                }

                // first check if we can start a trial for this course
                if (courseTrialsStore.userCanStartTrialForCourse(id).value) {
                    try {
                        await requestTrial(id);
                        // if this is successful we do no return since we can redirect the user below to the course
                    } catch (error) {
                        // trial was not possible so show buy modal
                        presentBuyModal(course.value);
                        return;
                    }
                } else {
                    // show buy modal if free trial is not possible or got rejected
                    presentBuyModal(course.value);
                    return;
                }
            }

            // has access so go to relevant page
            if (course.value?.openDayExercises) {
                router.push({ name: Routes.PRACTICE_AREA_COURSE_PLAN, params: { id } });
            } else {
                router.push({ name: Routes.PRACTICE_AREA_COURSE_PLAN_DASHBOARD, params: { id } });
            }
        };

        const startNewCourse = () => {
            // Doing this to prevent cached modals with possible old data (already selected values)
            router.push({ name: Routes.START_NEW_COURSE });
        };

        onIonViewDidEnter(() => {
            coursesStore.dispatchUpdateRoutineForAllCourses();
        });

        const refreshPage = async () => {
            await userStore.pullLatestUserData();
            await coursesStore.dispatchUpdateRoutineForAllCourses(true);
        };

        return {
            goToCourse,
            startNewCourse,
            coursesFullData,
            addCircleOutline,
            checkmarkOutline,
            barChartOutline,
            calendarOutline,
            trashOutline,
            closeOutline,
            informationCircleOutline,
            ribbonOutline,
            user,
            getAssetPath,
            clipboardOutline,
            refreshPage,
            showBuyModal,
            courseToBuy,
            doHideBuyModal,
            currentOfferingToShow,
            purchasePackage,
            lockClosedOutline,
            lockOpenOutline,
            currencyCodeMapping,
            convertReadableIsoDurationString,
            pkgSelected,
            isNativePlatform,
            cancelCourse,
        };
    },
});
