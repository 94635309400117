import { CourseModel, useCoursesStore } from '@/store/courses';
import { dayJsDe } from '@/services/Time.service';
import { getAxiosRequestInstance } from './Request.service';
import { AxiosResponse } from 'axios';
import { CourseDataModel } from '@/models/CourseData.model';
import { LoadLevelDataModel } from '@/models/Loadlevel.model';
import { loadLevelQuery } from '@/models/Loadlevel.query';
import { courseDataQuery } from '@/models/CourseData.query';
import { CoursesModel } from '@/models/Courses.model';
import { coursesDataQuery } from '@/models/Courses.query';
// import { loadingController } from '@ionic/vue';
import { useCourseData } from '@/store/courses/courseData';
import { showMessage } from './Notification.service';
import { getExerciseDisplayName } from './Exercise.service';
import { ribbonOutline } from 'ionicons/icons';
import { CurrentExerciseModel, CurrentExercisesModel } from '@/models/CurrentExercises.model';
import { debounce } from 'lodash';

// This fetches all courses but with limited populated fields. Just those which are needed to "start new course"
export const fetchAllCourses = (): Promise<AxiosResponse<CoursesModel>> => {
    const axios = getAxiosRequestInstance();
    return axios.get(`practice-areas/?${coursesDataQuery}`);
};

// This fetches all course data for one specific course with fully populated load levels
export const fetchCourseData = (courseId: number): Promise<AxiosResponse<CourseDataModel>> => {
    const axios = getAxiosRequestInstance();
    return axios.get(`practice-areas/${courseId}?${courseDataQuery}`);
};

export const fetchLevelData = (levelId: number): Promise<AxiosResponse<LoadLevelDataModel>> => {
    const axios = getAxiosRequestInstance();
    return axios.get(`loadlevels/${levelId}?${loadLevelQuery}`);
};

export const shouldStartNewLevel = (course: CourseModel): boolean => {
    return course.currentPoints >= course.currentPointsToReach;
};

export const shouldStartNewDay = (course: CourseModel): boolean => {
    if (!course.currentLevelDayStarted) {
        return false;
    }
    const now = dayJsDe();
    // console.log(now.format('YYYY-MM-DD HH:mm:ss'));
    const startedDate = dayJsDe(course.currentLevelDayStarted);
    // next day in the middle of the night (03:00) should be a safe bet
    const nextDayBreakpoint = startedDate.endOf('day').add(3, 'hours');

    if (now.isAfter(nextDayBreakpoint)) {
        return true;
    }
    return false;
};

export const updateCourseDataRoutine = async (courseId: number, forceServerRefresh = false) => {
    // const loading = await loadingController.create({});
    // await loading.present();
    const coursesStore = useCoursesStore();
    const course = coursesStore.getCourseById(courseId).value;

    if (!course) {
        return;
    }

    const courseDataStore = useCourseData();

    try {
        // check if we need to go to the next level and/or next day
        const newDayNeeded = shouldStartNewDay(course);
        const newLevelNeeded = shouldStartNewLevel(course);

        // get most recent area data
        const resArea = await courseDataStore.getCourseData(course.courseId, forceServerRefresh);

        // construct new course object
        let newCourse = { ...course };

        if (newLevelNeeded && newDayNeeded) {
            let newLevel = course.currentLevel;
            let newLevelId = course.currentLevelId;
            const maxPossibleLevel = resArea.data.attributes.loadlevels.length;
            if (course.currentLevel < maxPossibleLevel) {
                newLevel = course.currentLevel + 1;
                newLevelId = resArea.data.attributes.loadlevels[newLevel - 1].loadlevel.data.id;
                informNewLevel();
            } else {
                informMaxLevel();
            }
            newCourse = {
                ...newCourse,
                currentLevel: newLevel,
                currentLevelId: newLevelId,
                currentPoints: 0,
                currentPointsToReach: 0, // Note: currentPointsToReached is set later here because level data not loaded yet
                currentLevelDay: 1,
                currentLevelDayStarted: '',
                currentLevelDayDoneExercises: [],
            };
        }

        // load current needed loadlevel
        const loadLevel = await courseDataStore.getLoadLevel(newCourse.currentLevelId, forceServerRefresh);

        // check if we need to start a new day (this will never be needed if we started a new level before)
        if (newDayNeeded && !newLevelNeeded) {
            newCourse = startNewDay(newCourse, getMaxDay(loadLevel));
            informNewDay();
        }

        // set current points to reach if not set (or 0)
        // this will always (and only then) be the case when a new level started.
        // needs to be decoupled from above level upgrade check because new level data required for score info
        if (!newCourse.currentPointsToReach) {
            newCourse = {
                ...newCourse,
                currentPointsToReach: loadLevel.data.attributes.score,
            };
        }
        const coursesStore = useCoursesStore();
        coursesStore.updateCourse(newCourse);
    } catch (error: any) {
        if (!error.handled) {
            showMessage({
                header: 'Etwas ist schief gegangen...',
                message: 'Bitte versuche es später noch einmal.',
            });
        }
    } finally {
        // await loading.dismiss();
    }
};

const getMaxDay = (loadLevel: LoadLevelDataModel | null): number => {
    if (!loadLevel) {
        return 0;
    }
    return loadLevel.data.attributes.days.length;
};

export const getCurrentExercises = (
    course: CourseModel,
    loadLevel: LoadLevelDataModel | null
): Array<CurrentExercisesModel> => {
    if (!loadLevel) {
        return [];
    }
    const base = getLoadLevelExercises(course, loadLevel, 'basics');
    const additionals = getLoadLevelExercises(course, loadLevel, 'additionals');
    return [
        {
            title: 'Basic Übungen',
            exercises: base,
        },
        {
            title: 'Zusatz Übungen',
            exercises: additionals,
        },
    ].filter((group) => {
        return group.exercises.length;
    });
};

export const getOpenDayExercises = (course: CourseModel, loadLevel: LoadLevelDataModel | null): boolean => {
    if (!loadLevel) {
        return true;
    }
    return (
        getLoadLevelExercises(course, loadLevel, 'basics').some((item) => !item.done) ||
        getLoadLevelExercises(course, loadLevel, 'additionals').some((item) => !item.done)
    );
};

export const getOpenDayExercisesCount = (course: CourseModel, loadLevel: LoadLevelDataModel | null): number => {
    if (!loadLevel) {
        return 0;
    }
    return (
        getLoadLevelExercises(course, loadLevel, 'basics').filter((item) => !item.done).length +
        getLoadLevelExercises(course, loadLevel, 'additionals').filter((item) => !item.done).length
    );
};

const getLoadLevelExercises = (
    course: CourseModel | null,
    loadLevel: LoadLevelDataModel,
    keyName: 'basics' | 'additionals'
): Array<CurrentExerciseModel> => {
    if (loadLevel && course) {
        return loadLevel.data.attributes.days[course.currentLevelDay - 1][keyName].flatMap((item) => {
            if (item.practice.data === null) {
                return [];
            }
            const doneFound = course.currentLevelDayDoneExercises.find((el) => el.id === item.practice.data?.id);
            return [
                {
                    id: item.practice.data.id,
                    name: getExerciseDisplayName(item.practice),
                    done: !!doneFound,
                    doneScore: doneFound ? doneFound.score : 0,
                },
            ];
        });
    }
    return [];
};

const startNewDay = (oldCourse: CourseModel, maxDay: number) => {
    // defined [#46]: start with day 1 when last day in level was reached
    let selectedDay = 1;

    if (oldCourse.currentLevelDay < maxDay) {
        // go to next day if there is one
        selectedDay = oldCourse.currentLevelDay + 1;
    }

    return {
        ...oldCourse,
        currentLevelDay: selectedDay,
        currentLevelDayDoneExercises: [],
        currentLevelDayStarted: '',
    };
};

const informNewDay = debounce(() => {
    showMessage({
        header: 'Willkommen zurück',
        message: 'Viel Spaß mit den heutigen Übungen!',
    });
}, 500);

const informNewLevel = debounce(() => {
    showMessage({
        header: 'Du hast ein neues Level erreicht!',
        message: 'Ab sofort bekommst du etwas fortgeschrittenere Übungen. Viel Spaß!',
        icon: ribbonOutline,
        duration: 0,
    });
}, 500);

const informMaxLevel = debounce(() => {
    showMessage({
        header: 'Du hast das maximale Level erreicht!',
        message:
            'Wow, du hast alle Level & Übungen geschafft! Keine Sorge, du kannst weiterhin trainieren. Wir haben das Level für dich neu gestartet. Zusätzlich kannst du jederzeit deine Lieblings-Übungen im Archiv aufrufen.',
        icon: ribbonOutline,
        duration: 0,
    });
}, 500);
