
import { defineComponent } from 'vue';
import BasePageLayout from '@/components/BasePageLayout.vue';
import BaseHeader from '@/components/BaseHeader.vue';
import { useRouter } from 'vue-router';
import {
    IonItem,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonLabel,
    IonIcon,
    IonPage,
    onIonViewDidEnter,
    loadingController,
    alertController,
} from '@ionic/vue';
import { checkmarkCircleOutline, caretForwardCircleOutline, banOutline } from 'ionicons/icons';
import { Routes } from '@/router';
import CourseAreaSwitcher from '@/components/CourseAreaSwitcher.vue';
import { dayJsDe } from '@/services/Time.service';
import { IS_DEVELOPMENT_MODE } from '@/services/Environment.service';
import { useUserStore } from '@/store/user';
import { useCoursesStore } from '@/store/courses';
import { computed } from '@vue/reactivity';
import { updateCourseDataRoutine } from '@/services/Course.service';
import { getNetworkStatusIsOnline, presentHasNoNetworkAlert } from '@/services/Network.service';
import { useSkippedQuestionsStore } from '@/store/courses/skippedQuestions';

export default defineComponent({
    name: 'CoursePage',
    components: {
        BasePageLayout,
        BaseHeader,
        IonItem,
        IonLabel,
        IonCard,
        IonCardHeader,
        IonCardContent,
        IonCardSubtitle,
        IonButton,
        IonIcon,
        CourseAreaSwitcher,
        IonPage,
    },
    setup() {
        const router = useRouter();
        const courseId = Number(router.currentRoute.value.params.id);
        const userStore = useUserStore();
        const coursesStore = useCoursesStore();
        const course = coursesStore.getFullCourseDataById(courseId);
        const courseBase = coursesStore.getCourseById(courseId);
        const skippedQuestions = useSkippedQuestionsStore();
        const isSkippedQuestions = skippedQuestions.userHasSkippedQuestionsByCourse(courseId);

        const goToExercise = (exerciseId: number) => {
            if (getNetworkStatusIsOnline()) {
                router.push({
                    name: Routes.PRACTICE_AREA_EXERCISE,
                    params: { id: courseId, exercise: exerciseId },
                    query: { loadLevelNumber: course.value?.currentLevel },
                });
            } else {
                presentHasNoNetworkAlert();
            }
        };

        const goBackToOverview = () => {
            router.push({ name: Routes.PRACTICE_AREA });
        };

        const refreshPage = async () => {
            await userStore.pullLatestUserData();
            await updateCourseDataRoutine(courseId, true);
        };

        const hydrate = async () => {
            await updateCourseDataRoutine(courseId);
        };

        // this is the combination of "mounted" and "come back to existing (watcher)"
        onIonViewDidEnter(() => {
            hydrate();
        });

        const startNewCourse = async () => {
            const alert = await alertController.create({
                header: 'Hinweis',
                message:
                    'Wenn du deinen individuellen Kurs erstellen lässt, geht dein aktueller Fortschritt verloren. Der Kurs wird neu gestartet.',
                buttons: [
                    {
                        text: 'Abbrechen',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Individuellen Trainingsplan erstellen lassen',
                        id: 'confirm-button',
                        handler: async () => {
                            if (!course.value) {
                                return;
                            }
                            const loading = await loadingController.create({
                                message: 'gleich gehts weiter...',
                            });
                            await loading.present();
                            await new Promise((resolve) => setTimeout(resolve, 300));
                            try {
                                //coursesStore.cancelCourse(courseId);
                                await router.push({
                                    path: '/start-new-course',
                                    query: {
                                        id: courseId,
                                        restart: 'true',
                                    },
                                });
                            } catch (error) {
                                console.error('hups', error);
                            } finally {
                                loading.dismiss();
                            }
                        },
                    },
                ],
            });
            alert.present();
        };

        //NOTE: only for development
        const resetDay = () => {
            if (!courseBase.value) {
                return;
            }
            coursesStore.updateCourse({
                ...courseBase.value,
                currentLevelDayDoneExercises: [],
                currentLevelDayStarted: '',
            });
            hydrate();
        };

        //NOTE: only for development
        const simulateTomorrowDate = () => {
            if (courseBase && courseBase.value?.currentLevelDayStarted) {
                const currentLevelDayPlus24Hours = dayJsDe(courseBase.value.currentLevelDayStarted).subtract(
                    24,
                    'hours'
                );
                // next day in the middle of the night (03:00) should be a safe bet
                coursesStore.updateCourse({
                    ...courseBase.value,
                    currentLevelDayStarted: currentLevelDayPlus24Hours.toISOString(),
                });
                hydrate();
            }
        };

        return {
            course,
            currentExercises: computed(() => course.value?.currentExercises),
            openDayExercises: computed(() => course.value?.openDayExercises),
            goToExercise,
            goBackToOverview,
            resetDay,
            simulateTomorrowDate,
            hydrate,
            refreshPage,
            checkmarkCircleOutline,
            caretForwardCircleOutline,
            showDevTools: IS_DEVELOPMENT_MODE,
            banOutline,
            startNewCourse,
            isSkippedQuestions,
        };
    },
});
