import { ToastOptions } from '@ionic/core';
import { toastController } from '@ionic/vue';
import { informationCircle } from 'ionicons/icons';

export const showMessage = async (options: ToastOptions) => {
    const toast = await toastController.create({
        icon: informationCircle,
        position: 'top',
        duration: 15000,
        buttons: [
            {
                text: 'OK',
                role: 'cancel',
            },
        ],
        ...options,
    });
    await toast.present();
};
