type questionSetQuestion = {
    headline: string;
    options: questionSetOption[];
    selectedOption: questionSetOption | null;
};

type questionSetOption = {
    name: string;
    levelImpact: number;
};

export const getQuestionSet = (): Array<questionSetQuestion> => {
    return [
        {
            headline: 'Seit wann hast du Beschwerden?',
            selectedOption: null,
            options: [
                {
                    name: '< 3 Monate',
                    levelImpact: 0,
                },
                {
                    name: '3 - 12 Monate',
                    levelImpact: 0,
                },
                {
                    name: '1-5 Jahre',
                    levelImpact: 0,
                },
                {
                    name: '> 5 Jahre',
                    levelImpact: 0,
                },
            ],
        },
        {
            headline: 'Wie oft treten deine Beschwerden auf?',
            selectedOption: null,
            options: [
                {
                    name: '< 1x pro Woche',
                    levelImpact: 1,
                },
                {
                    name: '1x pro Woche',
                    levelImpact: 0,
                },
                {
                    name: 'Mehrmals pro Woche',
                    levelImpact: 0,
                },
                {
                    name: 'Täglich',
                    levelImpact: -1,
                },
            ],
        },
        {
            headline: 'Wann treten die Beschwerden auf?',
            selectedOption: null,
            options: [
                {
                    name: 'In Ruhe',
                    levelImpact: 0,
                },
                {
                    name: 'Bei Belastung',
                    levelImpact: -1,
                },
                {
                    name: 'In der Nacht',
                    levelImpact: 0,
                },
                {
                    name: 'Unregelmäßig',
                    levelImpact: 0,
                },
            ],
        },
        {
            headline: 'Wie verhalten sich deine Beschwerden?',
            selectedOption: null,
            options: [
                {
                    name: 'Werden im Laufe des Tages weniger',
                    levelImpact: 0,
                },
                {
                    name: 'Steigen im Laufe des Tages an',
                    levelImpact: 0,
                },
                {
                    name: 'Bei bestimmten Bewegungen stark',
                    levelImpact: 0,
                },
                {
                    name: 'Wechselhaft',
                    levelImpact: 0,
                },
            ],
        },
        {
            headline: 'Wie sportlich schätzt du dich ein?',
            selectedOption: null,
            options: [
                {
                    name: 'Sehr sportlich',
                    levelImpact: 0,
                },
                {
                    name: 'Mache regelmäßig Sport',
                    levelImpact: 0,
                },
                {
                    name: 'Eher unsportlich',
                    levelImpact: 0,
                },
                {
                    name: 'Ich bin sehr unsportlich',
                    levelImpact: -1,
                },
            ],
        },
        {
            headline: 'Alter',
            selectedOption: null,
            options: [
                {
                    name: 'Unter 29',
                    levelImpact: 0,
                },
                {
                    name: '30-49',
                    levelImpact: 0,
                },
                {
                    name: '50-65',
                    levelImpact: 0,
                },
                {
                    name: 'Über 65',
                    levelImpact: -1,
                },
            ],
        },
    ];
};
