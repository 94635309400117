import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63d75c82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-button__text" }
const _hoisted_2 = {
  key: 0,
  class: "nav-button__badge"
}
const _hoisted_3 = {
  key: 1,
  class: "nav-button__badge nav-button__badge--done"
}
const _hoisted_4 = {
  key: 2,
  class: "nav-button__badge nav-button__badge--warn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!

  return (_openBlock(), _createBlock(_component_ion_tabs, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_router_outlet, {
        animated: false,
        fullscreen: true
      }),
      _withDirectives(_createVNode(_component_ion_tab_bar, {
        slot: "bottom",
        class: "ion-no-border"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
            return (_openBlock(), _createBlock(_component_ion_tab_button, {
              key: index,
              tab: item.label,
              class: _normalizeClass(["nav-button", { 'nav-button--active': item.active }]),
              onClick: ($event: any) => (_ctx.goToRouteName(item.navigation))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: item.icon
                }, null, 8, ["icon"]),
                _createElementVNode("span", _hoisted_1, [
                  _createTextVNode(_toDisplayString(item.label) + " ", 1),
                  (item.countBadge)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.countBadge), 1))
                    : (item.badgeDone)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                          _createVNode(_component_ion_icon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                        ]))
                      : (item.badgeWarn)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                            _createVNode(_component_ion_icon, { icon: _ctx.alertOutline }, null, 8, ["icon"])
                          ]))
                        : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["tab", "class", "onClick"]))
          }), 128))
        ]),
        _: 1
      }, 512), [
        [_vShow, !_ctx.fullscreenView]
      ])
    ]),
    _: 1
  }))
}