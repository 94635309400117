
import { defineComponent, onMounted, ref } from 'vue';
import { IonContent, IonApp } from '@ionic/vue';
import PageToolbar from '@/components/PageToolbar.vue';
import VersionInfo from '@/components/VersionInfo.vue';
import { appIsHidden } from './services/Visibility.service';
import { useRouter } from 'vue-router';
import { Routes } from './router';
import { isNativePlatform, showStandaloneInfoIfNeeded } from './services/Standalone.service';
import { useCourseData } from './store/courses/courseData';
import { useCoursesStore } from './store/courses';
import { useUserStore } from './store/user';
import { initNetworkStatusWatcher } from './services/Network.service';

export default defineComponent({
    name: 'App',
    components: {
        IonContent,
        IonApp,
        PageToolbar,
        VersionInfo,
    },
    setup() {
        const router = useRouter();
        const courseDataStore = useCourseData();
        const versionInfoRef = ref<InstanceType<typeof VersionInfo> | null>(null);

        onMounted(async () => {
            const coursesStore = useCoursesStore();
            const userStore = useUserStore();

            initNetworkStatusWatcher();

            document.addEventListener('visibilitychange', async () => {
                if (!appIsHidden()) {
                    // do not reload the page when in registration process. This prevents lost form data.
                    if (router.currentRoute.value.name === Routes.REGISTER) {
                        return;
                    }
                    if (isNativePlatform()) {
                        // a hard page reload does not help on native devices because they dont fetch latest version on reload
                        // so just refresh course data to be sure when app was not open for longer time
                        await userStore.pullLatestUserData();
                        await coursesStore.dispatchUpdateRoutineForAllCourses();
                        // do always when opening the app. Initially its called in mounted hook inside component
                        versionInfoRef.value?.evaluateStatus();
                    } else {
                        // NOTE: we do always a page refresh if user re-enters the app. (app was open but not active screen, different browser tab, standby mode, etc.)
                        // this forces to use app updates as soon as they are available.
                        // should not be any issue for the user. probably not event noticed because tokens are stored and routes are exact.
                        // it also triggers course routine update function (see below in mounted block)
                        window.location.reload();
                    }
                }
            });

            // #50 standalone notification
            showStandaloneInfoIfNeeded();

            // check app cache and invalidate if needed
            courseDataStore.checkCacheVersions();

            // always check one when accessing the app
            await coursesStore.dispatchUpdateRoutineForAllCourses();
        });

        return {
            versionInfoRef,
        };
    },
});
