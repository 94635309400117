
import { IonCard, IonCardHeader, IonCardContent, IonBadge, IonIcon } from '@ionic/vue';
import { marked } from 'marked';
import { defineComponent, PropType, ref } from 'vue';
import { useCoursesStore } from '@/store/courses';
import { getAssetPath } from '@/services/Request.service';
import { informationCircleOutline, exitOutline } from 'ionicons/icons';
//import { CourseDataModel } from '@/models/CourseData.model';

export default defineComponent({
    name: 'CourseDetailItems',
    components: {
        IonCard,
        IonCardHeader,
        IonBadge,
        IonIcon,
        IonCardContent,
    },
    props: {
        courseDetails: {
            type: Object as PropType<any | null>,
            required: true,
        },
    },
    emits: ['course-start-button-clicked'],
    setup(props, { emit }) {
        const coursesStore = useCoursesStore();
        const accordionOpen = ref<Record<number, boolean>>({});

        accordionOpen.value = {};

        const renderedMarkdown = (markdown: string) => {
            return marked(markdown);
        };

        const courseIsDisabled = (courseId: number) => {
            const courses = coursesStore.getCourses;
            return courses.some((item) => item.courseId === courseId);
        };

        const emitEvent = (courseId: number) => {
            emit('course-start-button-clicked', courseId);
        };

        const openAccordion = (courseId: number) => {
            if (courseIsDisabled(courseId)) {
                return false;
            }
            //set(accordionOpen.value, courseId, true);
            if (!accordionOpen.value?.[courseId]) {
                accordionOpen.value = { ...accordionOpen.value, [courseId]: true };
            } else {
                accordionOpen.value = { ...accordionOpen.value, [courseId]: false };
            }
        };

        const isAccordionOpen = (courseId: number) => {
            return accordionOpen.value?.[courseId];
        };

        return {
            getAssetPath,
            courseIsDisabled,
            renderedMarkdown,
            emitEvent,
            informationCircleOutline,
            exitOutline,
            openAccordion,
            isAccordionOpen,
        };
    },
});
