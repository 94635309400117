import dayjs from 'dayjs';
import 'dayjs/locale/de';
dayjs.locale('de');
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const dayJsDe = dayjs;

export const getCurrentDateString = () => {
    return new Date().toISOString();
};

export const getCurrentDateStringDayOnly = () => {
    return dayJsDe().format('YYYY-MM-DD');
};

export const getReadableDate = (date: any) => {
    return dayJsDe(date).format('DD.MM.YYYY');
};

export const getReadableYear = (date: any) => {
    return dayJsDe(date).format('YYYY');
};

export const convertReadableIsoDurationString = (durationString: string | null): string => {
    if (!durationString) {
        return '';
    }

    const isoDurationRegex = /^P(\d+)([YMD])$/;
    const matches = durationString.match(isoDurationRegex);

    if (!matches) {
        return durationString; // Input does not match the expected format, return the original string
    }

    const durationValue = parseInt(matches[1], 10);
    const durationUnit = matches[2];
    let unitString = '';

    switch (durationUnit) {
        case 'Y':
            unitString = 'Jahr';
            break;
        case 'M':
            unitString = 'Monat';
            break;
        case 'D':
            unitString = 'Tag';
            break;
        default:
            break;
    }

    return `${durationValue} ${unitString}${durationValue > 1 ? 'e' : ''}`;
};
