import { computed } from 'vue';
import { defineStore } from 'pinia';
import { getAxiosRequestInstance } from '@/services/Request.service';
import { useUserStore } from '@/store/user';
import { AxiosResponse } from 'axios';

export type SkippedQuestionsData = {
    courseId: number;
    isSkipped: boolean;
};

export const useSkippedQuestionsStore = defineStore('skippedQuestions', () => {
    const userStore = useUserStore();

    const updateSkipQuestions = (
        courseId: number,
        skippedQuestions: boolean
    ): Promise<AxiosResponse<Array<SkippedQuestionsData>>> => {
        const axios = getAxiosRequestInstance();
        return axios.post('/skip_questions', {
            courseId,
            skippedQuestions,
        });
    };

    const userHasSkippedQuestionsByCourse = (courseId: number) => {
        return computed(() => {
            const user = userStore.user;

            // Check if user and skippedQuestionsData are defined
            if (user?.skippedQuestionsData) {
                // Attempt to find the course in skippedQuestionsData
                const foundCourse = user.skippedQuestionsData.find((item) => item.courseId === courseId);

                // If found, return the isSkipped value; otherwise, return false
                return foundCourse ? foundCourse.isSkipped : false;
            }

            // Return false if user or skippedQuestionsData is undefined
            return false;
        });
    };

    const requestSkippedQuestions = async (courseId: number, isSkipped: boolean) => {
        try {
            const skipQuestionsResponse = await updateSkipQuestions(courseId, isSkipped);

            if (userStore.user) {
                // update local user object with new trial object from backend
                userStore.setUser({
                    ...userStore.user,
                    skippedQuestionsData: skipQuestionsResponse.data,
                });
            }

            return skipQuestionsResponse.data.find((item) => item.courseId === courseId);
        } catch (error) {
            console.error('could not set skipped questions', error);
            return false;
        }
    };

    return {
        requestSkippedQuestions,
        updateSkipQuestions,
        userHasSkippedQuestionsByCourse,
    };
});
