
import {
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonButton,
    IonIcon,
    IonRow,
    IonCheckbox,
    toastController,
    loadingController,
} from '@ionic/vue';
import { informationCircle, checkmarkCircle, arrowForwardCircle } from 'ionicons/icons';
import { defineComponent, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import { Routes } from '@/router';
import { getAxiosRequestInstance } from '@/services/Request.service';
import BasePageLayout from '@/components/BasePageLayout.vue';
import { getReadableServerMessage } from '@/services/Translations.service';
import BaseHeader from '@/components/BaseHeader.vue';

export default defineComponent({
    name: 'RegisterPage',
    components: {
        IonInput,
        IonItem,
        IonLabel,
        IonPage,
        IonButton,
        IonIcon,
        IonRow,
        IonCheckbox,
        BasePageLayout,
        BaseHeader,
    },
    setup() {
        const router = useRouter();
        const userStore = useUserStore();

        const userWantsTestAccount: Ref<boolean> = ref(false);
        const email: Ref<string> = ref('');
        const username: Ref<string> = ref('');
        const password1: Ref<string> = ref('');
        const password2: Ref<string> = ref('');
        const birthdayDay: Ref<string> = ref('');
        const birthdayMonth: Ref<string> = ref('');
        const birthdayYear: Ref<string> = ref('');
        const gender: Ref<string | null> = ref(null);
        const checkedAgb: Ref<boolean> = ref(false);
        const checkedNewsletter: Ref<boolean> = ref(false);

        const complete = async () => {
            if (password1.value !== password2.value) {
                showMessage({ message: 'Die Passwörter stimmen nicht überein' });
                return;
            }
            if (!checkedAgb.value) {
                showMessage({ message: 'Die AGBs müssen akzeptiert werden' });
                return;
            }

            const loading = await loadingController.create({
                message: 'Wir erstellen gerade deinen Account...',
            });
            await loading.present();

            try {
                // const params = checkedNewsletter.value ? '?newsletter=true' : '';

                const axiosRequestInstance = getAxiosRequestInstance();
                await axiosRequestInstance.put('/update_me_base_data', {
                    email: email.value,
                    password: password1.value,
                });

                showMessage({
                    header: 'Fast geschafft',
                    message:
                        'Du hast eine E-Mail mit einem Bestätigungslink bekommen. Dies kann einige Minuten dauern... Bestätige deine E-Mail-Adresse indem du auf den Link klickst. Danach kannst du dich hier einloggen.',
                    icon: checkmarkCircle,
                });

                await userStore.resetAll(false);
                await router.push({ name: Routes.LOGIN });
            } catch (error: any) {
                if (!error.response) {
                    showMessage({ header: 'Hups, hier ist etwas schief gegangen!', message: 'Unbekannter Fehler' });
                    return;
                }
                const serverMessage = error.response.data.error.message;
                showMessage({
                    header: 'Hups, hier ist etwas schief gegangen!',
                    message: getReadableServerMessage(serverMessage),
                });
                return;
            } finally {
                await loading.dismiss();
            }
        };

        const showMessage = async (overrides: any) => {
            const toast = await toastController.create({
                icon: informationCircle,
                position: 'top',
                buttons: [
                    {
                        text: 'OK',
                        role: 'cancel',
                    },
                ],
                ...overrides,
            });
            await toast.present();
        };

        return {
            userWantsTestAccount,
            email,
            username,
            password1,
            password2,
            checkedAgb,
            checkedNewsletter,
            birthdayYear,
            birthdayMonth,
            birthdayDay,
            gender,
            complete,
            arrowForwardCircle,
        };
    },
});
