
import { defineComponent, onMounted, nextTick } from 'vue';
import { IonContent, IonModal, IonButton, IonIcon } from '@ionic/vue';
import { ref } from '@vue/reactivity';
import { logoGooglePlaystore, logoAppleAppstore } from 'ionicons/icons';

import { AppUpdate } from '@capawesome/capacitor-app-update';
import version from '@/version.json';
import semverGte from 'semver/functions/gte';
import { getApiVersionInfo } from '@/services/Version.service';
import { marked } from 'marked';
import { getOperatingSystem } from '@/services/Standalone.service';
import { isNativePlatform } from '@/services/Standalone.service';

export default defineComponent({
    name: 'VersionInfo',
    components: {
        IonModal,
        IonContent,
        IonButton,
        IonIcon,
    },
    setup() {
        const operatingSystem = ref('');
        const isNative = isNativePlatform();

        const showModal = ref(false);
        const modalMessage = ref('');
        const requiredVersion = ref('');
        const latestVersion = ref('');
        const maintenanceMode = ref(false);
        const canDismiss = ref(false);
        const provideRefreshButton = ref(false);

        const evaluateStatus = async () => {
            modalMessage.value = '';
            requiredVersion.value = '';
            latestVersion.value = '';
            maintenanceMode.value = false;
            canDismiss.value = false;
            provideRefreshButton.value = false;

            try {
                const apiInfo = await getApiVersionInfo();

                if (apiInfo.maintenanceMode) {
                    maintenanceMode.value = true;
                    showModal.value = true;
                    modalMessage.value = marked(apiInfo.maintenanceModeMessage || '');
                    return;
                }

                // check required version info of app
                const currentVersionSatisfiesRequiredVersion = semverGte(version.versionName, apiInfo.requiredVersion);
                if (!currentVersionSatisfiesRequiredVersion) {
                    requiredVersion.value = apiInfo.requiredVersion;
                    showModal.value = true;
                    modalMessage.value = marked(apiInfo.requiredVersionMessage || '');
                    return;
                }

                // check latest version info of app
                const latestVersionIsUsed = semverGte(version.versionName, apiInfo.latestVersion);
                if (!latestVersionIsUsed) {
                    latestVersion.value = apiInfo.latestVersion;
                    showModal.value = true;
                    canDismiss.value = true;
                    modalMessage.value = marked(apiInfo.latestVersionMessage || '');
                    return;
                }

                // fallback mechanism if modal was shown, api call done again and no more need to present modal now
                canDismiss.value = true;
                await nextTick();
                closeModal();
            } catch (error) {
                showModal.value = true;
                maintenanceMode.value = true;
                provideRefreshButton.value = true;
                modalMessage.value =
                    '<h2>Hier ist etwas schief gegangen...</h2><p>Aktuell gibt es leider Verbindungsprobleme mit dem Server. Bitte versuche es später nochmal.</p>';
            }
        };

        onMounted(async () => {
            operatingSystem.value = await getOperatingSystem();
            evaluateStatus();
        });

        const openAppStore = () => {
            AppUpdate.openAppStore();
        };

        const closeModal = () => {
            showModal.value = false;
        };

        return {
            showModal,
            modalMessage,
            version: version.versionName,
            requiredVersion,
            latestVersion,
            maintenanceMode,
            logoGooglePlaystore,
            logoAppleAppstore,
            operatingSystem,
            isNative,
            canDismiss,
            provideRefreshButton,
            openAppStore,
            closeModal,
            evaluateStatus,
        };
    },
});
