import qs from 'qs';

export const courseDataQuery = qs.stringify(
    {
        populate: {
            loadlevels: {
                populate: '*',
            },
            image: {
                fields: ['url'],
            },
            introVideo: {
                fields: ['url', 'mime'],
            },
            introVideoPoster: {
                fields: ['url'],
            },
        },
    },
    {
        encodeValuesOnly: true,
    }
);
