import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50d80e8b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "countdown__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_vue_countdown = _resolveComponent("vue-countdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: "countdown",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startCountdown && _ctx.startCountdown(...args)))
  }, [
    _createVNode(_component_ion_icon, {
      class: _normalizeClass(["countdown__icon", { 'countdown__icon--animate': _ctx.counting }]),
      icon: _ctx.stopwatchOutline
    }, null, 8, ["icon", "class"]),
    (_ctx.counting)
      ? (_openBlock(), _createBlock(_component_vue_countdown, {
          key: 0,
          interval: 100,
          time: _ctx.timeInSeconds * 1000,
          tag: "div",
          class: "countdown__text",
          onEnd: _ctx.onCountdownEnd
        }, {
          default: _withCtx(({ totalSeconds }) => [
            _createTextVNode(" noch " + _toDisplayString(_ctx.readableTime(totalSeconds)) + " Sek. ", 1)
          ]),
          _: 1
        }, 8, ["time", "onEnd"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.timeInSeconds) + " Sek. Timer starten", 1))
  ]))
}