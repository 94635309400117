import { defineStore } from 'pinia';
import { RemovableRef, useStorage } from '@vueuse/core';
import { ref } from 'vue';
import { fetchLevelData, fetchCourseData } from '@/services/Course.service';
import { LoadLevelDataModel } from '@/models/Loadlevel.model';
import { CourseDataModel } from '@/models/CourseData.model';
import { computed } from '@vue/reactivity';

interface cacheEntry<T> {
    id: number;
    entry: T;
}

interface CoursesDataStateModel {
    courseData: RemovableRef<Array<cacheEntry<CourseDataModel>>>;
    loadLevelData: RemovableRef<Array<cacheEntry<LoadLevelDataModel>>>;
    cacheVersion: RemovableRef<number>;
}

export const useCourseData = defineStore('courseData', () => {
    const state = ref<CoursesDataStateModel>({
        courseData: useStorage('courseData', []),
        loadLevelData: useStorage('loadLevelData', []),
        cacheVersion: useStorage('courseDataCacheVersion', 0),
    });

    const wipeCourseData = () => {
        state.value.loadLevelData = [];
        state.value.courseData = [];
    };

    // NOTE: Use this if you need to invalidate cache with a new version!
    const COURSE_DATA_CACHE_VERSION = 3;
    const checkCacheVersions = () => {
        if (state.value.cacheVersion < COURSE_DATA_CACHE_VERSION) {
            wipeCourseData();
            console.log(
                `Course Data Cache got wiped. Had old Version v${state.value.cacheVersion} - Required Version v${COURSE_DATA_CACHE_VERSION}`
            );
            state.value.cacheVersion = COURSE_DATA_CACHE_VERSION;
        }
    };

    const getCourseDataSynchron = (id: number) =>
        computed((): CourseDataModel | null => {
            const cached = state.value.courseData.find((item) => item.id === id);
            if (cached) {
                return cached.entry;
            }
            return null;
        });

    const getLoadLevelSynchron = (id: number | undefined) =>
        computed((): LoadLevelDataModel | null => {
            if (!id) {
                return null;
            }
            const cached = state.value.loadLevelData.find((item) => item.id === id);
            if (cached) {
                return cached.entry;
            }
            return null;
        });

    const getCourseData = async (id: number, forceServerRefresh = false): Promise<CourseDataModel> => {
        if (!forceServerRefresh) {
            const cached = getCourseDataSynchron(id);
            if (cached.value) {
                return cached.value;
            }
        }
        const response = await fetchCourseData(id);
        const cleanedUpStore = state.value.courseData.filter((item) => item.id !== id);
        state.value.courseData = [
            ...cleanedUpStore,
            {
                id,
                entry: response.data,
            },
        ];
        return response.data;
    };

    const getLoadLevel = async (id: number, forceServerRefresh = false): Promise<LoadLevelDataModel> => {
        if (!forceServerRefresh) {
            const cached = getLoadLevelSynchron(id);
            if (cached.value) {
                return cached.value;
            }
        }
        const response = await fetchLevelData(id);
        const cleanedUpStore = state.value.loadLevelData.filter((item) => item.id !== id);
        state.value.loadLevelData = [
            ...cleanedUpStore,
            {
                id,
                entry: response.data,
            },
        ];
        return response.data;
    };

    return {
        state,
        checkCacheVersions,
        getLoadLevel,
        getCourseData,
        getCourseDataSynchron,
        getLoadLevelSynchron,
        wipeCourseData,
    };
});
