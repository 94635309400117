
import { Routes } from '@/router';
import { useCoursesStore } from '@/store/courses';
import { computed, defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { IonIcon } from '@ionic/vue';
import { checkmarkOutline } from 'ionicons/icons';

export default defineComponent({
    name: 'CourseAreaSwitcher',
    components: {
        IonIcon,
    },
    props: {
        activeId: {
            type: Number as PropType<number>,
            default: 1,
        },
        archiveCount: {
            type: Number as PropType<number>,
            default: 0,
        },
    },
    setup(props) {
        const router = useRouter();
        const courseId = Number(router.currentRoute.value.params.id);
        const coursesStore = useCoursesStore();
        const course = coursesStore.getFullCourseDataById(courseId);

        const tabItems = computed(() => {
            const openExercises = course.value?.openDayExercisesCount;
            return [
                {
                    id: 1,
                    routeName: Routes.PRACTICE_AREA_COURSE_PLAN_DASHBOARD,
                    label: 'Übersicht',
                },
                {
                    id: 2,
                    routeName: Routes.PRACTICE_AREA_COURSE_PLAN,
                    label: 'Tagesplan',
                    badge: openExercises ? openExercises : 'done',
                },
                {
                    id: 3,
                    routeName: Routes.PRACTICE_AREA_COURSE_PLAN_ARCHIVE,
                    label: props.archiveCount > 0 ? `Übungsarchiv (${props.archiveCount})` : 'Übungsarchiv',
                },
            ];
        });

        const clickItem = (routeName: Routes) => {
            router.push({ name: routeName });
        };

        return {
            tabItems,
            clickItem,
            checkmarkOutline,
        };
    },
});
