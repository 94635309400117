import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b882d1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-label" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_progress_bar, {
      color: "primary",
      value: _ctx.getProgressPercent / 100
    }, null, 8, ["value"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, "Stufen-Fortschritt: " + _toDisplayString(_ctx.getProgressPercent) + "%", 1),
      (_ctx.course)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(" Stufe: "),
            _createElementVNode("strong", null, _toDisplayString(_ctx.course?.currentLevel), 1),
            _createTextVNode(" / " + _toDisplayString(_ctx.course?.courseLevelCount), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}