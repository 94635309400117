import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-044f27a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text" }
const _hoisted_2 = { class: "small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_icon, {
      class: "box-icon",
      icon: _ctx.calendarOutline,
      size: "large"
    }, null, 8, ["icon"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("strong", null, "Tag " + _toDisplayString(_ctx.currentDayNumber), 1),
      _createTextVNode(),
      _createElementVNode("span", _hoisted_2, "| Begonnen am: " + _toDisplayString(_ctx.readableStartDate), 1)
    ])
  ], 64))
}