import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { alertController } from '@ionic/vue';

const isInStandaloneMode = () => {
    return (
        window.matchMedia('(display-mode: standalone)').matches ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.navigator.standalone ||
        document.referrer.includes('android-app://')
    );
};

export const isNativePlatform = () => {
    return Capacitor.isNativePlatform();
};

export const getOperatingSystem = async () => {
    const info = await Device.getInfo();
    return info.operatingSystem;
};

export const showStandaloneInfoIfNeeded = async () => {
    const localStorageKey = 'standaloneHint';
    const showedValue = 'showed';
    if (localStorage.getItem(localStorageKey) === showedValue) {
        // user got already some hint once.
        return;
    }
    if (isInStandaloneMode()) {
        // no need to show. Is already.
        return;
    }
    if (isNativePlatform()) {
        // no need for this on native apps
        return;
    }
    const infoTextIos =
        'Klicke auf den Teilen-Button unten in der Mitte, dann: <i>"Zum Startbildschirm hinzufügen"</i>.';
    const infoTextAndroid = 'Klicke in deinem Browser auf <i>"..."</i>, dann <i>"Zum Startbildschirm hinzufügen"</i>.';
    let additionalInfoText = '';

    try {
        const info = await Device.getInfo();
        if (info.operatingSystem === 'ios') {
            additionalInfoText = infoTextIos;
        } else if (info.operatingSystem === 'android') {
            additionalInfoText = infoTextAndroid;
        } else {
            // no need to show this info on mac / windows systems - only mobile relevant
            return;
        }
    } catch (error) {
        console.log('capactitor "device" plugin seens not to work', error);
    }

    // hint user
    await new Promise((resolve) => setTimeout(resolve, 3000));
    const alert = await alertController.create({
        header: 'Als App installieren',
        message: `Du kannst <b>Physio On</b> als App auf deinem Handy hinzufügen um schnell zu deinen Übungen zu gelangen. <br><br>${additionalInfoText}`,
        buttons: [
            {
                text: 'Alles klar',
                role: 'cancel',
                cssClass: 'secondary',
            },
        ],
    });
    alert.present();

    // do not show it again.
    localStorage.setItem(localStorageKey, showedValue);
};
