
import { defineComponent, ref, computed, Ref } from 'vue';
import BasePageLayout from '@/components/BasePageLayout.vue';
import BaseHeader from '@/components/BaseHeader.vue';
import ExerciseCountdown from '@/components/ExerciseCountdown.vue';
import {
    IonHeader,
    IonToolbar,
    IonButtons,
    // IonTitle,
    IonButton,
    IonModal,
    IonContent,
    IonIcon,
    IonFabButton,
    IonFab,
    IonPage,
    IonCard,
    IonCardHeader,
    onIonViewWillLeave,
    loadingController,
    onIonViewDidEnter,
    onIonViewWillEnter,
} from '@ionic/vue';

import { useRoute, useRouter } from 'vue-router';
import {
    getExercise,
    getExerciseDescription,
    getExerciseDisplayName,
    saveExerciseToBackend,
} from '@/services/Exercise.service';
import { Routes } from '@/router';
import { checkmark, arrowBackOutline, ellipseOutline, ellipse } from 'ionicons/icons';
import { useCoursesStore } from '@/store/courses';
import { getAssetPath } from '@/services/Request.service';
import { ExerciseModel } from '@/models/Exercise.model';
import { marked } from 'marked';
import { showMessage } from '@/services/Notification.service';
import { getCurrentDateString } from '@/services/Time.service';
import { useUserStore } from '@/store/user';

export default defineComponent({
    name: 'ExercisePage',
    components: {
        BasePageLayout,
        BaseHeader,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonTitle,
        IonButton,
        IonModal,
        IonContent,
        IonIcon,
        IonFab,
        IonFabButton,
        IonPage,
        IonCard,
        IonCardHeader,
        ExerciseCountdown,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const isArchiveExercise = route.name === Routes.PRACTICE_AREA_COURSE_PLAN_ARCHIVE_EXERCISE;
        const courseId = Number(router.currentRoute.value.params.id);
        const exerciseId = Number(router.currentRoute.value.params.exercise);
        const loadLevelNumber = Number(router.currentRoute.value.query.loadLevelNumber);
        const exercise = ref<null | ExerciseModel>(null);
        const score: Ref<number> = ref(0);
        const showModal = ref(false);
        const showRatingAnswer = ref(false);
        const videoPlayerRef = ref(null);
        const starsCount = 5;
        const userStore = useUserStore();
        const showTourStep1 = ref(false);
        const showTourStep2 = ref(false);
        const showTourStep3 = ref(false);
        const showTimer = ref(true);

        const clickStar = (index: number) => {
            score.value = index;
        };

        const clickDone = () => {
            showModal.value = true;
            showTourStep1.value = false;
            showTourStep2.value = false;
            if (userStore.onboardingTourRequired) {
                showTourStep3.value = true;
            }
        };

        const goBackToCategory = () => {
            if (isArchiveExercise) {
                router.push({ name: Routes.PRACTICE_AREA_COURSE_PLAN_ARCHIVE });
            } else {
                router.push({
                    name: Routes.PRACTICE_AREA_COURSE_PLAN,
                    params: { id: router.currentRoute.value.params.id },
                });
            }
        };

        const clickFeedbackDone = async () => {
            if (!score.value) {
                return;
            }
            const loading = await loadingController.create({});
            await loading.present();
            try {
                if (isArchiveExercise) {
                    await saveExerciseToBackend({
                        exerciseId,
                        practiceArea: courseId,
                        score: score.value,
                        lastTimeDone: getCurrentDateString(),
                    });
                    router.push({ name: Routes.PRACTICE_AREA_COURSE_PLAN_ARCHIVE });
                } else {
                    const coursesStore = useCoursesStore();
                    await coursesStore.finishedCourseExercise(courseId, exerciseId, loadLevelNumber, score.value);
                    const latestCourseData = coursesStore.getFullCourseDataById(courseId).value;
                    if (latestCourseData && latestCourseData.openDayExercisesCount > 0) {
                        router.push({
                            name: Routes.PRACTICE_AREA_COURSE_PLAN,
                            params: { id: router.currentRoute.value.params.id },
                        });
                    } else {
                        router.push({
                            name: Routes.PRACTICE_AREA_COURSE_PLAN_DASHBOARD,
                            params: { id: router.currentRoute.value.params.id },
                        });
                    }
                }
            } catch (error: any) {
                if (!error.handled) {
                    showMessage({
                        header: 'Etwas ist schief gegangen...',
                        message: 'Bitte versuche es später noch einmal.',
                    });
                }
            } finally {
                hideModal();
                loading.dismiss();
                if (userStore.onboardingTourRequired) {
                    userStore.onboardingTourDone();
                }
            }
        };

        const infoBoxValues = computed(() => {
            if (exercise.value) {
                return [
                    {
                        name: 'Wiederholungen',
                        value: exercise.value.data.attributes.repetitions,
                    },
                    {
                        name: 'Sekunden',
                        value: exercise.value.data.attributes.seconds,
                    },
                    {
                        name: 'Durchgänge',
                        value: exercise.value.data.attributes.sets,
                    },
                ].filter((item) => !!item.value);
            }
            return [];
        });

        const exerciseDescriptionHtml = computed(() => {
            if (exercise.value) {
                return marked(getExerciseDescription(exercise.value));
            }
            return '';
        });

        const exerciseTimer = computed(() => {
            if (!exercise.value) {
                return 0;
            }
            const seconds = Number(exercise.value.data.attributes.seconds);
            return seconds;
            // TODO: should we activate this only for >= 20?
            // if (seconds && seconds >= 20) {
            //     return seconds;
            // }
            // return 0;
        });

        const videoEnded = async () => {
            if (userStore.onboardingTourRequired) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                showTourStep2.value = true;
                await new Promise((resolve) => setTimeout(resolve, 3000));
                showTourStep2.value = false;
            }
        };

        const videoStarted = () => {
            showTourStep1.value = false;
        };

        const hideModal = () => {
            showModal.value = false;
        };

        const hideRatingAnswer = () => {
            showRatingAnswer.value = false;
        };

        onIonViewWillEnter(async () => {
            // display timer
            showTimer.value = true;

            try {
                const response = await getExercise(exerciseId);
                exercise.value = response.data;
                if (isArchiveExercise && route.query.oldScore) {
                    score.value = Number(route.query.oldScore);
                }
            } catch (error: any) {
                router.replace({ name: Routes.HOME });
                if (!error.handled) {
                    showMessage({
                        header: 'Etwas ist schief gegangen...',
                        message: 'Bitte versuche es später noch einmal.',
                    });
                }
            }
        });

        onIonViewDidEnter(async () => {
            if (userStore.onboardingTourRequired) {
                await new Promise((resolve) => setTimeout(resolve, 500));
                showTourStep1.value = true;
            }
        });

        onIonViewWillLeave(() => {
            // force unmount timer to rerender component
            showTimer.value = false;

            // just to be sure modal is closed. User could use native browser/mobile back button and modal would stick open...
            hideModal();
            // reset always
            showTourStep1.value = false;
            showTourStep2.value = false;
            showTourStep3.value = false;
            // make sure video is paused. Else it keeps playing if you leave via router.
            if (videoPlayerRef.value !== null) {
                try {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    videoPlayerRef.value.pause();
                } catch (error) {
                    console.error(error);
                }
            }
        });

        return {
            starsCount,
            exercise,
            showModal,
            showRatingAnswer,
            hideRatingAnswer,
            hideModal,
            score,
            clickStar,
            clickDone,
            clickFeedbackDone,
            goBackToCategory,
            arrowBackOutline,
            ellipseOutline,
            ellipse,
            checkmark,
            getAssetPath,
            exerciseDescriptionHtml,
            getExerciseDisplayName,
            getExerciseDescription,
            infoBoxValues,
            videoPlayerRef,
            videoEnded,
            videoStarted,
            showTourStep1,
            showTourStep2,
            showTourStep3,
            exerciseTimer,
            showTimer,
        };
    },
});
