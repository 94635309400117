import { defineStore } from 'pinia';
import { RemovableRef, useStorage } from '@vueuse/core';
import { getAxiosRequestInstance } from '@/services/Request.service';
import { computed, ref, watch } from 'vue';
import { useUserStore } from '@/store/user';
import { saveExerciseToBackend } from '@/services/Exercise.service';
import hash from 'object-hash';
import { debounce } from 'lodash';
import { appIsHidden } from '@/services/Visibility.service';
import {
    getCurrentExercises,
    getOpenDayExercises,
    getOpenDayExercisesCount,
    updateCourseDataRoutine,
} from '@/services/Course.service';
import { useCourseData } from './courseData';
import { getCurrentDateString, getCurrentDateStringDayOnly } from '@/services/Time.service';
import { showMessage } from '@/services/Notification.service';
import { ribbonOutline } from 'ionicons/icons';
import { CurrentExercisesModel } from '@/models/CurrentExercises.model';
import { usePaymentStore } from '../payment';
import { useCourseTrials } from './courseTrials';

export interface CourseModel {
    courseId: number;
    currentLevel: number;
    currentLevelDay: number;
    currentLevelDayStarted: string;
    currentPoints: number;
    currentPointsToReach: number;
    currentLevelDayDoneExercises: Array<DoneExercises>;
    overallDoneExercises: Array<DoneExercises>;
    currentLevelId: number;
    courseStarted: string;
    trainingDays: Array<string>;
}

export type CourseModelFullData = CourseModel & {
    courseName: string;
    courseImage: string;
    courseLevelCount: number;
    currentExercises: Array<CurrentExercisesModel>;
    openDayExercises: boolean;
    openDayExercisesCount: number;
    willReachNewLevel: boolean;
    userCanUseCourse: boolean;
    userHasActiveTrial: boolean;
    userCanStartTrial: boolean;
    freeCourse: boolean;
    introVideoUrl: string;
    introVideoType: string;
    introVideoPoster: string;
    askQuestionSetOnStart?: boolean;
};

interface DoneExercises {
    id: number;
    score: number;
    date: string;
}

interface CoursesStateModel {
    courses: RemovableRef<Array<CourseModel>>;
}

export const useCoursesStore = defineStore('courses', () => {
    const state = ref<CoursesStateModel>({
        courses: useStorage('courses', []),
    });

    const latestServerControlHash = useStorage('latestServerControlHash', '');
    const currentlySyncing = ref(false);
    const courseData = useCourseData();
    const paymentStore = usePaymentStore();
    const userStore = useUserStore();
    const courseTrials = useCourseTrials();

    const getCourses = computed((): Array<CourseModel> => {
        return state.value.courses;
    });

    const getCoursesFullData = computed((): Array<CourseModelFullData> => {
        return state.value.courses
            .map((course) => {
                const courseBaseData = courseData.getCourseDataSynchron(course.courseId);
                const loadLevel = courseData.getLoadLevelSynchron(course.currentLevelId);
                const currentExercises = getCurrentExercises(course, loadLevel.value);
                const openDayExercises = getOpenDayExercises(course, loadLevel.value);
                const openDayExercisesCount = getOpenDayExercisesCount(course, loadLevel.value);
                const courseId = courseBaseData.value?.data.id;

                return {
                    ...course,
                    courseName: courseBaseData.value?.data.attributes.name || '',
                    courseImage: courseBaseData.value?.data.attributes.image.data?.attributes.url || '',
                    courseLevelCount: courseBaseData.value?.data.attributes.loadlevels.length || 0,
                    currentExercises,
                    openDayExercises,
                    openDayExercisesCount,
                    willReachNewLevel: course.currentPoints >= course.currentPointsToReach,
                    userCanUseCourse: paymentStore.userCanUseCourse(courseId).value,
                    userCanStartTrial: courseTrials.userCanStartTrialForCourse(courseId).value,
                    userHasActiveTrial: !!courseTrials.userHasActiveTrialForCourse(courseId).value,
                    freeCourse: paymentStore.getIsFreeCourse(courseId).value,
                    introVideoUrl: courseBaseData.value?.data.attributes.introVideo?.data?.attributes.url || '',
                    introVideoType: courseBaseData.value?.data.attributes.introVideo?.data?.attributes.mime || '',
                    introVideoPoster:
                        courseBaseData.value?.data.attributes.introVideoPoster?.data?.attributes.url || '',
                    askQuestionSetOnStart: courseBaseData.value?.data.attributes.askQuestionSetOnStart,
                };
            })
            .sort((a, b) => {
                if (a.userCanUseCourse && !b.userCanUseCourse) {
                    return -1; // a soll vor b stehen
                } else if (!a.userCanUseCourse && b.userCanUseCourse) {
                    return 1; // b soll vor a stehen
                } else {
                    return 0; // keine Änderung der Reihenfolge
                }
            });
    });

    const getCourseById = (id: number) =>
        computed((): CourseModel | null => {
            const course = getCourses.value.find((item) => item.courseId === id);
            if (course) {
                return course;
            }
            return null;
        });

    const getFullCourseDataById = (id: number) =>
        computed(() => {
            const course = getCoursesFullData.value.find((item) => item.courseId === id);
            if (course) {
                return course;
            }
            return null;
        });

    const dispatchUpdateRoutineForAllCourses = async (forceServerRefresh = false) => {
        for (let index = 0; index < state.value.courses.length; index++) {
            const course = state.value.courses[index];

            await updateCourseDataRoutine(course.courseId, forceServerRefresh);
        }
    };

    const addNewCourse = async (courseId: number, startLevel: number) => {
        const newCoursePlan = await createNewCourse(courseId, startLevel, true);
        state.value.courses.push(newCoursePlan);
        // always request a trial. function will check on its own if needed and if possible
        await courseTrials.requestCourseTrial(courseId);
    };

    const createNewCourse = async (
        courseId: number,
        startLevel: number,
        forceRefresh = false
    ): Promise<CourseModel> => {
        const { data } = await courseData.getCourseData(courseId, forceRefresh);
        const coursePlan: CourseModel = {
            courseId,
            courseStarted: getCurrentDateString(),
            currentPoints: 0,
            currentPointsToReach: data.attributes.loadlevels[startLevel - 1].loadlevel.data.attributes.score,
            currentLevel: startLevel,
            currentLevelId: data.attributes.loadlevels[startLevel - 1].loadlevel.data.id,
            currentLevelDay: 1,
            currentLevelDayStarted: '',
            currentLevelDayDoneExercises: [],
            overallDoneExercises: [],
            trainingDays: [],
        };
        return coursePlan;
    };

    const finishedCourseExercise = async (
        courseId: number,
        exerciseId: number,
        loadLevelNumber: number,
        points: number
    ) => {
        const course = getCourseById(courseId);
        if (!course.value) {
            return;
        }

        const newCurrentPoints = course.value.currentPoints + points;
        // check if user qualified for new level but has still some exercises to do today
        if (
            newCurrentPoints >= course.value.currentPointsToReach &&
            course.value.currentPoints < course.value.currentPointsToReach
        ) {
            informNextDayNewLevel();
        }

        // create history item
        const doneExerciseItem = { id: exerciseId, score: points, date: getCurrentDateString() };

        // NOTE: be careful to not (re)use objects as references (multiple times). creates different hash for some reason
        // other way would be to json stringify and parse again. but maybe this is expensive so for now stay with this...
        const newCurrentLevelDayDoneExercises = [...course.value.currentLevelDayDoneExercises, { ...doneExerciseItem }];

        // [#98] store not all time history to save data space and increase performance for possible long time users
        const KEEP_HISTORY_ITEMS_COUNT = 14;
        const newOverallDoneExercises = [{ ...doneExerciseItem }, ...course.value.overallDoneExercises]
            .sort((a, b) => {
                return b.date.localeCompare(a.date);
            })
            .slice(0, KEEP_HISTORY_ITEMS_COUNT);

        const updatedCourse: CourseModel = {
            ...course.value,
            currentPoints: newCurrentPoints,
            currentLevelDayDoneExercises: newCurrentLevelDayDoneExercises,
            overallDoneExercises: newOverallDoneExercises,
            ...(!course.value.currentLevelDayStarted && { currentLevelDayStarted: getCurrentDateString() }),
            trainingDays: [...new Set([getCurrentDateStringDayOnly(), ...course.value.trainingDays])],
        };

        // make sure changes are done in backend before we update our course. to avoid conflicts with updatedAt logic.
        // TODO: may introduce here (on backend side) some "doneCount" or some "history" json to track user activity and create some data for analystics
        await saveExerciseToBackend({
            score: points,
            exerciseId,
            practiceArea: courseId,
            loadLevelNumber,
            lastTimeDone: getCurrentDateString(),
        });

        updateCourse(updatedCourse);
    };

    const updateCourse = (course: CourseModel) => {
        const newState = state.value.courses.map((item) => {
            if (item.courseId === course.courseId) {
                return course;
            }
            return item;
        });
        state.value.courses = newState;
    };

    const cancelCourse = (courseId: number) => {
        // TODO: consider what needs to be deleted in backend? course feedback items?
        const newState = state.value.courses.filter((item) => {
            return item.courseId !== courseId;
        });

        state.value.courses = newState;
    };

    const hydrateCourses = async (courses: Array<CourseModel>) => {
        latestServerControlHash.value = hash(courses);

        // make sure latest object is used (backwards compatibility)
        const enforcedUpToDateCourses = await Promise.all(
            courses.map(async (item) => {
                const enforcedCourse = await createNewCourse(item.courseId, item.currentLevel);
                return {
                    ...enforcedCourse,
                    ...item,
                };
            })
        );

        // finally put it in store
        state.value.courses = enforcedUpToDateCourses;
    };

    const clearCourses = () => {
        state.value.courses = [];
        latestServerControlHash.value = '';
    };

    const snyncLatestCourseData = debounce(async () => {
        // prevent multiple server updates because more than one open browser tab
        // useStorage reactivity in combination with the watcher would trigger mutliple update requests
        // this has error potential and needs to be prevented
        if (appIsHidden()) {
            return;
        }
        if (!userStore.isAuthenticated) {
            return;
        }
        if (currentlySyncing.value) {
            // currently running, set a timeout to try again in 2 second
            setTimeout(() => snyncLatestCourseData(), 2000);
            return;
        }
        const newControlHash = hash(state.value.courses);
        if (newControlHash === latestServerControlHash.value) {
            // no data change to course object
            return;
        }

        try {
            currentlySyncing.value = true;
            const axios = getAxiosRequestInstance();
            await axios.put('/update_me', {
                courses: state.value.courses,
                controlHash: latestServerControlHash.value,
            });
            latestServerControlHash.value = newControlHash;
        } catch (error) {
            console.error(error);
        } finally {
            currentlySyncing.value = false;
        }
    }, 700);

    // sync all changes back to api whenever something changes
    watch(state.value, () => {
        snyncLatestCourseData();
    });

    return {
        state,
        getCourses,
        getCourseById,
        addNewCourse,
        hydrateCourses,
        clearCourses,
        updateCourse,
        finishedCourseExercise,
        cancelCourse,
        getCoursesFullData,
        getFullCourseDataById,
        dispatchUpdateRoutineForAllCourses,
    };
});

const informNextDayNewLevel = () => {
    showMessage({
        header: 'Ab morgen trainierst du in der nächsten Belastungsstufe!',
        message: 'Wenn du für heute noch Übungen offen hast, kannst du diese jetzt trotzdem weiter machen.',
        icon: ribbonOutline,
        duration: 0,
    });
};
