
import { Routes } from '@/router';
import { useCoursesStore } from '@/store/courses';
import { useUserStore } from '@/store/user';
import { IonIcon, IonTabBar, IonTabButton, IonTabs, IonRouterOutlet } from '@ionic/vue';
import { personOutline, clipboardOutline, checkmarkOutline, addCircleOutline, alertOutline } from 'ionicons/icons';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'PageToolbar',
    components: { IonIcon, IonTabBar, IonTabButton, IonTabs, IonRouterOutlet },
    setup() {
        const router = useRouter();
        const coursesStore = useCoursesStore();
        const userStore = useUserStore();

        const menuItems = computed(() => {
            const totalExerciseCount = coursesStore.getCoursesFullData.reduce((prev, curr) => {
                return (prev += curr.openDayExercisesCount);
            }, 0);
            return [
                {
                    icon: addCircleOutline,
                    label: 'Neuer Kurs',
                    navigation: Routes.START_NEW_COURSE,
                },
                {
                    icon: clipboardOutline,
                    label: 'Deine Kurse',
                    navigation: Routes.PRACTICE_AREA,
                    active: router.currentRoute.value.path.startsWith(`/${Routes.PRACTICE_AREA}`),
                    countBadge: !coursesStore.getCourses.length ? 0 : totalExerciseCount ? totalExerciseCount : 0,
                    badgeDone: !coursesStore.getCourses.length ? false : !!totalExerciseCount,
                },
                {
                    icon: personOutline,
                    label: 'Profil',
                    navigation: Routes.PROFILE,
                    active: router.currentRoute.value.name === Routes.PROFILE,
                    badgeWarn: userStore.isAnonymousTestUser,
                },
            ];
        });

        const goToRouteName = (routeName: Routes) => {
            if (routeName === 'start-new-course') {
                router.push({ name: routeName });
            } else {
                router.push({ name: routeName });
            }
        };

        const fullscreenView = computed(() => {
            // NOTE: can be used to hide header/footer. But causes ugly jumps
            return !!router.currentRoute.value.meta.fullscreen;
            // return false;
        });

        return {
            menuItems,
            fullscreenView,
            goToRouteName,
            checkmarkOutline,
            alertOutline,
        };
    },
});
