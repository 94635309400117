
import { defineComponent } from 'vue';
import { IonItem, IonIcon } from '@ionic/vue';
import { heart, heartOutline, checkmarkCircleOutline, lockClosedOutline } from 'ionicons/icons';

export default defineComponent({
    name: 'ArchiveExerciseItem',
    components: {
        IonItem,
        IonIcon,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        clickAction: {
            type: Function,
            required: true,
        },
        clickFavoriteAction: {
            type: Function,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            default: () => {},
        },
        score: {
            type: Number,
            default: 0,
        },
        level: {
            type: Number,
            default: 0,
        },
        image: {
            type: String,
            required: true,
        },
        favorite: {
            type: Boolean,
            default: false,
        },
        locked: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {
            heart,
            heartOutline,
            checkmarkCircleOutline,
            lockClosedOutline,
        };
    },
});
