import qs from 'qs';

export const getExerciseRatingsQuery = (practiceArea: number, sort: Array<string>) => {
    return qs.stringify(
        {
            populate: {
                exercise: {
                    fields: ['displayName', 'id'],
                    populate: {
                        baseExercise: {
                            fields: ['displayName', 'description'],
                            populate: {
                                videoPoster: {
                                    fields: ['url'],
                                },
                            },
                        },
                    },
                },
            },
            filters: {
                practiceArea: {
                    id: {
                        $eq: practiceArea,
                    },
                },
            },
            pagination: {
                pageSize: 100,
            },
            sort,
        },
        {
            encodeValuesOnly: true,
        }
    );
};
