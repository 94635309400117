
import { CourseModelFullData } from '@/store/courses';
import { defineComponent, PropType } from 'vue';
import { IonIcon } from '@ionic/vue';
import { checkmarkOutline, fitnessOutline, informationOutline } from 'ionicons/icons';
import { computed } from '@vue/reactivity';

export default defineComponent({
    name: 'CourseExercisesAverageStats',
    components: {
        IonIcon,
    },
    props: {
        course: {
            type: Object as PropType<CourseModelFullData | null>,
            required: true,
        },
    },
    setup(props) {
        const LAST_EXERCISE_COUNT_TO_RESPECT = 7;

        const exercisesAverage = computed(() => {
            if (!props.course || props.course.overallDoneExercises.length === 0) {
                return 0;
            }
            const limitedSortedExercisesToCompare = [...props.course.overallDoneExercises]
                .sort((a, b) => {
                    return b.date.localeCompare(a.date);
                })
                .slice(0, LAST_EXERCISE_COUNT_TO_RESPECT);
            const summedUpScores = limitedSortedExercisesToCompare.reduce((prev, curr) => {
                return (prev += curr.score);
            }, 0);
            return summedUpScores / limitedSortedExercisesToCompare.length;
        });

        const readableAverage = computed(() => {
            if (exercisesAverage.value === 0) {
                return '...';
            }
            return exercisesAverage.value.toFixed(1);
        });

        const userInfoBox = computed(() => {
            if (exercisesAverage.value < 3) {
                return {
                    icon: informationOutline,
                    color: 'warning',
                    text: 'Fallen dir deine Übungen zu schwer oder hast du Schmerzen? Gib uns Feedback!',
                };
            }
            if (exercisesAverage.value < 4) {
                return {
                    icon: checkmarkOutline,
                    color: 'success',
                    text: '​​Gut! Aber steigere dich noch in den Übungen, die dir am schwersten gefallen sind.',
                };
            }
            return {
                icon: checkmarkOutline,
                color: 'success',
                text: 'Sehr gut! Du scheinst super voran zu kommen.',
            };
        });

        return {
            exercisesAverage,
            readableAverage,
            userInfoBox,
            checkmarkOutline,
            fitnessOutline,
        };
    },
});
