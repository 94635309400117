import qs from 'qs';

const practicePopulation = {
    populate: {
        practice: {
            fields: ['id', 'displayName'],
            populate: {
                baseExercise: {
                    fields: ['displayName'],
                    populate: {
                        videoPoster: {
                            fields: ['url'],
                        },
                    },
                },
            },
        },
    },
};

export const loadLevelQuery = qs.stringify(
    {
        populate: {
            days: {
                populate: {
                    basics: {
                        ...practicePopulation,
                    },
                    additionals: {
                        ...practicePopulation,
                    },
                },
            },
        },
    },
    {
        encodeValuesOnly: true,
    }
);
