
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Routes } from '@/router';
import {
    IonButton,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonButtons,
    IonList,
    IonItem,
    IonLabel,
    IonProgressBar,
    IonRange,
    loadingController,
    toastController,
    onIonViewWillEnter,
} from '@ionic/vue';
import {
    closeOutline,
    arrowBackOutline,
    checkmarkCircleOutline,
    happyOutline,
    sadOutline,
    informationCircle,
} from 'ionicons/icons';
import { useUserStore } from '@/store/user';
import { useCoursesStore } from '@/store/courses';
import { fetchAllCourses } from '@/services/Course.service';
import { CoursesCourseData, CoursesModel } from '@/models/Courses.model';
import { getAssetPath } from '@/services/Request.service';
import CourseDetailItems from '@/components/CourseDetailItems.vue';
import { getQuestionSet } from '@/services/StartCourseQuestions.service';
import { useSkippedQuestionsStore } from '@/store/courses/skippedQuestions';

export default defineComponent({
    name: 'StartNewCourse',
    components: {
        IonPage,
        IonContent,
        IonButton,
        IonHeader,
        IonToolbar,
        IonIcon,
        IonButtons,
        IonList,
        IonItem,
        IonLabel,
        IonProgressBar,
        IonRange,
        CourseDetailItems,
    },
    setup() {
        const router = useRouter();
        const userStore = useUserStore();
        const skippedQuestionsStore = useSkippedQuestionsStore();
        const coursesStore = useCoursesStore();

        const META_STEP_COUNT = 2; // course select, doctor hint
        const practiceAreas: Ref<CoursesModel | null> = ref(null);

        // changeable values. Need to be reset before start
        const questionSet = ref(getQuestionSet());
        const selectedCourseId: Ref<number> = ref(0);
        const pain: Ref<number> = ref(5);

        const level: Ref<number> = ref(3);
        const isSkipped: Ref<boolean> = ref(false);

        onIonViewWillEnter(() => {
            if (!router.currentRoute.value.query.restart || router.currentRoute.value.query.restart != 'true') {
                // always reset all values on enter to provide a fresh question set
                selectedCourseId.value = 0;
                questionSet.value = getQuestionSet();
                pain.value = 5;
                isSkipped.value = false;
                hydrateAreas();
                // always start on page 1
                // if user comes back straight after started a course this is needed
                // this is especially useful if user uses browser back button
                router.replace({ name: Routes.START_NEW_COURSE, query: { step: 1 } });
            } else {
                selectedCourseId.value = 0;
                questionSet.value = getQuestionSet();
                selectedCourseId.value = Number(router.currentRoute.value.query.id);
                isSkipped.value = false;
                hydrateAreas();
                router.replace({
                    name: Routes.START_NEW_COURSE,
                    query: { step: 2, id: selectedCourseId.value, restart: 'true' },
                });
            }
        });

        const hydrateAreas = async () => {
            const res = await fetchAllCourses();
            practiceAreas.value = res.data;
        };

        const selectedCourse: ComputedRef<CoursesCourseData | null> = computed(() => {
            if (practiceAreas.value === null) {
                return null;
            }
            const foundCourse = practiceAreas.value.data.find((item) => item.id === selectedCourseId.value);
            if (foundCourse) {
                return foundCourse;
            }
            return null;
        });

        const questionCount = computed(() => {
            if (askQuestionSetOnStart.value) {
                return questionSet.value.length + 1; // +1 for the pain slider step
            }
            return 0;
        });

        const askQuestionSetOnStart = computed(() => {
            return !!selectedCourse.value?.attributes.askQuestionSetOnStart;
        });

        // all steps without intro slides
        const baseStepsCount = computed(() => {
            if (askQuestionSetOnStart.value) {
                return META_STEP_COUNT + questionCount.value;
            }
            return META_STEP_COUNT - 1; // course select, doctor hint
        });

        // how many steps we have to do in total
        const totalStepsCount = computed(() => {
            if (!selectedCourse.value) {
                return baseStepsCount.value;
            }
            return (
                baseStepsCount.value +
                selectedCourse.value.attributes.introSlides.length +
                (courseIntroVideo.value ? 1 : 0)
            );
        });

        const showDoctorHintStep = computed(() => {
            if (!askQuestionSetOnStart.value) {
                return false;
            }
            return page.value === baseStepsCount.value; // this is the last of base steps
        });

        /*const showSummaryPage = computed(() => {
            if (!askQuestionSetOnStart.value) {
                return false;
            }
            return page.value === baseStepsCount.value; // this is the last of base steps
        });*/

        const currentQuestion = computed(() => {
            if (!selectedCourse.value?.attributes.askQuestionSetOnStart) {
                return null;
            }
            if (page.value >= 4 && page.value <= questionSet.value.length + 2) {
                // indexes at 0 and skip 2 steps before...
                return questionSet.value[page.value - 3];
            }
            return null;
        });

        const courseIntroVideo = computed(() => {
            if (!selectedCourse.value) {
                return undefined;
            }
            return selectedCourse.value.attributes.introVideo?.data?.attributes;
        });

        const courseIntroVideoPoster = computed(() => {
            if (!selectedCourse.value) {
                return undefined;
            }
            return selectedCourse.value.attributes.introVideoPoster?.data?.attributes.url;
        });

        const currentIntroSlideImage = computed(() => {
            if (!selectedCourse.value || page.value <= baseStepsCount.value) {
                return '';
            }
            return selectedCourse.value.attributes.introSlides[page.value - baseStepsCount.value - 1]?.graphic.data
                .attributes.url;
        });

        const page = computed(() => {
            return router.currentRoute.value.query.step ? Number(router.currentRoute.value.query.step) : 1;
        });

        const buttonText = computed(() => {
            if (page.value < totalStepsCount.value && page.value != baseStepsCount.value) {
                return 'Weiter';
            }
            if (page.value === baseStepsCount.value) {
                return 'Verstanden';
            }
            return 'Lass uns loslegen';
        });

        const nextDisabled = computed(() => {
            if (page.value >= 1 && selectedCourseId.value === 0) {
                return true;
            }
            if (currentQuestion.value && !currentQuestion.value.selectedOption) {
                return true;
            }
            return false;
        });

        const handleDetailsClick = (courseId: number) => {
            selectedCourseId.value = courseId;
            nextPage();
        };

        const questionSetLevelImpact = computed(() => {
            return questionSet.value.reduce((prev, current) => {
                const selected = current.selectedOption;
                return prev + (selected?.levelImpact || 0);
            }, 0);
        });

        const getLevel = () => {
            // should not happen but typescript requires it
            if (!selectedCourse.value) {
                return 1;
            }

            const painNum = Number(pain.value);
            const levelCount = selectedCourse.value.attributes.loadlevels.length;

            // [#120] Courses with less or equal then 3 levels will always start at level 1
            if (levelCount < 4) {
                return 1;
            }

            // [#65] apply rules for small courses with less than 7 loadlevels
            // return level value from 1-3 or the max possible level if course has only 1 or 2 levels
            if (levelCount < 7) {
                if (painNum <= 2) {
                    return Math.min(3, levelCount);
                }
                if (painNum <= 6) {
                    return Math.min(2, levelCount);
                }
                return 1;
            }

            // [#120] new calculation for loadlevel
            // rules for "normal/big" courses
            let current = 0;

            if (painNum < 2) {
                current = 5;
            } else if (painNum < 4) {
                current = 4;
            } else if (painNum < 7) {
                current = 3;
            } else if (painNum < 9) {
                current = 2;
            } else {
                current = 1;
            }

            // after we have the base value depending on the pain we need to respect the questionSet impact
            current += questionSetLevelImpact.value;

            // now return the final level between 1-5
            if (current > 5) {
                return 5;
            }
            if (current < 1) {
                return 1;
            }

            return current;
        };

        const nextPage = async () => {
            if (page.value < totalStepsCount.value && !isSkipped.value) {
                router.push({ name: Routes.START_NEW_COURSE, query: { step: `${page.value + 1}` } });
                return;
            }

            if (selectedCourseId.value > 0 || isSkipped.value) {
                const loading = await loadingController.create({
                    message: 'Gleich kannst du loslegen!',
                });

                await loading.present();
                await new Promise((resolve) => setTimeout(resolve, 300));
                try {
                    level.value = getLevel();
                    // if course is already in user list, better: check if is in store
                    coursesStore.cancelCourse(selectedCourseId.value);
                    await coursesStore.addNewCourse(selectedCourseId.value, level.value);
                    await skippedQuestionsStore.requestSkippedQuestions(selectedCourseId.value, isSkipped.value);
                    goToCourses();
                } catch (error) {
                    console.log(error);
                    const toast = await toastController.create({
                        header: 'Hups, hier ist etwas schief gegangen!',
                        message: 'Kurs konnte nicht erstellt werden.',
                        icon: informationCircle,
                        position: 'top',
                        duration: 5000,
                        buttons: [
                            {
                                text: 'OK',
                                role: 'cancel',
                            },
                        ],
                    });
                    await toast.present();
                } finally {
                    loading.dismiss();
                }
                return;
            }
            router.push({ name: Routes.START_NEW_COURSE, query: { step: `1` } });
        };

        const courseIsDisabled = (courseId: number) => {
            const courses = coursesStore.getCourses;
            return courses.some((item) => item.courseId === courseId);
        };

        const cancel = () => {
            router.push({ name: Routes.PRACTICE_AREA });
        };

        const goToCourses = () => {
            router.push({ name: Routes.PRACTICE_AREA_COURSE_PLAN, params: { id: selectedCourseId.value } });
        };

        const back = () => {
            router.back();
        };

        const skipQuestions = () => {
            const levelCount = selectedCourse.value?.attributes.loadlevels.length;
            if (levelCount && levelCount <= 3) {
                level.value = 1;
            } else {
                level.value = 3;
            }

            isSkipped.value = true;
            router.replace({
                name: Routes.START_NEW_COURSE,
                query: { step: 100 },
            });
            //nextPage();
        };

        const practiceAreasGroups = computed(() => {
            if (!practiceAreas.value) {
                return [];
            }

            const categoryList = practiceAreas.value.data.map((item) => item.attributes.category || 'z_Sonstige');
            const uniqueList = [...new Set(categoryList)].sort();
            const preparedListItems = practiceAreas.value.data
                .map((item) => {
                    return {
                        id: item.id,
                        category: item.attributes.category || 'z_Sonstige',
                        name: item.attributes.name,
                        askQuestionSetOnStart: !!item.attributes.askQuestionSetOnStart,
                        requiredEntitlements: item.attributes.requiredEntitlement,
                        offers: item.attributes.revenuecatOffers,
                        image: item.attributes.image,
                        courseCaution: item.attributes.courseCaution,
                        courseDescription: item.attributes.courseDescription,
                        exerciseCount: item.attributes.exerciseCount,
                        loadlevelCount: item.attributes.loadlevelCount,
                        order: item.attributes.order,
                        duration: item.attributes.duration,
                        durationExercises: item.attributes.durationExercises,
                        courseGoodStuff: item.attributes.courseGoodStuff,
                        teamMembers: item.attributes.teamMember,
                        coursePrice: item.attributes.coursePrice,
                        linkToCourse: item.attributes.linkToCourse,
                        disabledAlreadyStarted: courseIsDisabled(item.id),
                        disabledUserIsNotAllowed: item.attributes.requiredEntitlement && userStore.isAnonymousTestUser,
                    };
                })
                .map((item) => {
                    return {
                        ...item,
                        disabled: item.disabledAlreadyStarted || item.disabledUserIsNotAllowed,
                    };
                })
                .sort((x, y) => {
                    const xDis = x.disabled;
                    const yDis = y.disabled;
                    return xDis === yDis ? 0 : xDis ? 1 : -1;
                });

            return uniqueList.map((category) => {
                return {
                    name: category.slice(2), // remove prefix which is there for sorting. e.g. "a_"
                    options: preparedListItems
                        .filter((item) => item.category === category)
                        .sort((a, b) => (a.order ?? Number.MAX_SAFE_INTEGER) - (b.order ?? Number.MAX_SAFE_INTEGER)),
                };
            });
        });

        return {
            questionCount,
            closeOutline,
            arrowBackOutline,
            checkmarkCircleOutline,
            happyOutline,
            sadOutline,
            page,
            totalStepsCount,
            buttonText,
            practiceAreasGroups,
            selectedCourseId,
            selectedCourse,
            nextDisabled,
            pain,
            baseStepsCount,
            currentIntroSlideImage,
            currentQuestion,
            questionSetLevelImpact,
            showDoctorHintStep,
            //showSummaryPage,
            courseIntroVideo,
            askQuestionSetOnStart,
            getAssetPath,
            courseIsDisabled,
            nextPage,
            back,
            cancel,
            goToCourses,
            getLevel,
            handleDetailsClick,
            courseIntroVideoPoster,
            skipQuestions,
        };
    },
});
