
import { CourseModelFullData } from '@/store/courses';
import { defineComponent, PropType } from 'vue';
import { IonIcon } from '@ionic/vue';
import { calendarOutline } from 'ionicons/icons';
import { computed } from '@vue/reactivity';
import { dayJsDe, getReadableDate } from '@/services/Time.service';

export default defineComponent({
    name: 'CourseProgress',
    components: {
        IonIcon,
    },
    props: {
        course: {
            type: Object as PropType<CourseModelFullData | null>,
            required: true,
        },
    },
    setup(props) {
        const currentDayNumber = computed(() => {
            if (!props.course) {
                return 0;
            }
            return Math.max(
                Math.floor(
                    dayJsDe().startOf('day').diff(dayJsDe(props.course.courseStarted).startOf('day'), 'days', true) + 1
                ),
                1
            );
        });

        const readableStartDate = computed(() => {
            if (!props.course) {
                return '-';
            }
            return getReadableDate(props.course.courseStarted);
        });

        return {
            currentDayNumber,
            readableStartDate,
            calendarOutline,
        };
    },
});
