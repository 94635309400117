export const getReadableServerMessage = (serverMessage: string) => {
    let result = serverMessage;
    const translations = [
        {
            original: 'Invalid identifier or password',
            translation: 'Nutzername oder Passwort sind nicht korrekt',
        },
        {
            original: 'Email is already taken',
            translation: 'Diese E-Mail hat bereits einen Account',
        },
        {
            original: 'An application error occured',
            translation: 'Unbekannter Server Fehler - Bitte versuche es später nochmal',
        },
        {
            original: 'Register action is currently disabled',
            translation: 'Es ist aktuell nicht möglich einen neuen Account zu erstellen',
        },
        {
            original: 'ValidationError: This attribute must be unique',
            translation: 'Diese E-Mail Adresse hat bereits einen Account',
        },
        {
            original: 'Your account email is not confirmed',
            translation: 'Du musst noch deine E-Mail-Adresse bestätigen. Prüfe auch dein Spam-Postfach!',
        },
        {
            original: 'The device or user is not allowed to make the purchase.',
            translation:
                'Abgelehnt. Auf diesem Gerät oder mit ihrem User-Account sind In-App Käufe leider nicht möglich.',
        },
    ];

    const foundTranslation = translations.find((item) => item.original === serverMessage);
    if (foundTranslation) {
        result = foundTranslation.translation;
    }
    return `<b>${result}</b>`;
};
