
import { IonPage, IonButton } from '@ionic/vue';
import { arrowForwardCircle } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Routes } from '@/router';
import { requestResetPassword } from '@/services/Request.service';
import BasePageLayout from '@/components/BasePageLayout.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/vue/css/ionic-swiper.css';

export default defineComponent({
    name: 'StartPage',
    components: {
        IonPage,
        BasePageLayout,
        Swiper,
        SwiperSlide,
        IonButton,
    },
    setup() {
        const router = useRouter();

        const navigateRegister = () => {
            router.push({ name: Routes.REGISTER });
        };

        const navigateLogin = () => {
            router.push({ name: Routes.LOGIN });
        };

        const navigateTest = () => {
            router.push({ name: Routes.TESTACCOUNT });
        };

        return {
            navigateRegister,
            navigateLogin,
            navigateTest,
            requestResetPassword,
            arrowForwardCircle,
            modules: [Navigation, Pagination, Scrollbar, A11y],
        };
    },
});
