
import { computed, defineComponent, ref } from 'vue';
import BasePageLayout from '@/components/BasePageLayout.vue';
import BaseHeader from '@/components/BaseHeader.vue';
import { useRouter } from 'vue-router';
import {
    IonCard,
    IonCardHeader,
    IonCardContent,
    alertController,
    loadingController,
    IonIcon,
    IonPage,
    onIonViewDidEnter,
    onIonViewWillLeave,
    IonButton,
} from '@ionic/vue';
import { ribbonOutline, banOutline } from 'ionicons/icons';
import { useCoursesStore } from '@/store/courses';
import { Routes } from '@/router';
import CourseDateState from '@/components/CourseDateState.vue';
import CourseExercisesAverageStats from '@/components/CourseExercisesAverageStats.vue';
import CourseAverageStats from '@/components/CourseAverageStats.vue';
import CourseProgress from '@/components/CourseProgress.vue';
import CourseAreaSwitcher from '@/components/CourseAreaSwitcher.vue';
import { getAssetPath } from '@/services/Request.service';
import { updateCourseDataRoutine } from '@/services/Course.service';
import { useUserStore } from '@/store/user';
import { useSkippedQuestionsStore } from '@/store/courses/skippedQuestions';

export default defineComponent({
    name: 'CourseDashboardPage',
    components: {
        BasePageLayout,
        BaseHeader,
        IonCard,
        IonCardHeader,
        IonCardContent,
        IonIcon,
        CourseAreaSwitcher,
        IonPage,
        CourseProgress,
        CourseDateState,
        CourseAverageStats,
        CourseExercisesAverageStats,
        IonButton,
    },
    setup() {
        const router = useRouter();
        const courseId = Number(router.currentRoute.value.params.id);
        const userStore = useUserStore();
        const coursesStore = useCoursesStore();
        const course = coursesStore.getFullCourseDataById(courseId);
        const skippedQuestions = useSkippedQuestionsStore();
        const isSkippedQuestions = skippedQuestions.userHasSkippedQuestionsByCourse(courseId);

        const introVideoPlayerRef = ref(null);

        const goBackToOverview = () => {
            router.push({ name: Routes.PRACTICE_AREA });
        };
        const courseIntroVideo = computed(() => {
            if (!course.value) {
                return undefined;
            }
            return course.value.introVideoUrl;
        });

        const goToExercises = () => {
            router.push({ name: Routes.PRACTICE_AREA_COURSE_PLAN });
        };

        const startNewCourse = async () => {
            const alert = await alertController.create({
                header: 'Hinweis',
                message:
                    'Wenn du deinen individuellen Kurs erstellen lässt, geht dein aktueller Fortschritt verloren. Der Kurs wird neu gestartet.',
                buttons: [
                    {
                        text: 'Abbrechen',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Individuellen Trainingsplan erstellen lassen',
                        id: 'confirm-button',
                        handler: async () => {
                            if (!course.value) {
                                return;
                            }
                            const loading = await loadingController.create({
                                message: 'gleich gehts weiter...',
                            });
                            await loading.present();
                            await new Promise((resolve) => setTimeout(resolve, 300));
                            try {
                                //coursesStore.cancelCourse(course.value.courseId);
                                // update skippedQuestions to be false
                                await router.push({
                                    path: '/start-new-course',
                                    query: {
                                        id: courseId,
                                        restart: 'true',
                                    },
                                });
                            } catch (error) {
                                console.error('hups', error);
                            } finally {
                                loading.dismiss();
                            }
                        },
                    },
                ],
            });
            alert.present();
        };

        const cancelCourse = async () => {
            const alert = await alertController.create({
                header: 'Bist du sicher?',
                message:
                    'Möchtest du den Kurs wirklich abbrechen? Dein Fortschritt in diesem Kurs geht damit verloren. Du kannst aber jederzeit den Kurs von neuem beginnen.',
                buttons: [
                    {
                        text: 'Doch weitermachen',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Jetzt beenden',
                        id: 'confirm-button',
                        handler: async () => {
                            if (!course.value) {
                                return;
                            }
                            const loading = await loadingController.create({
                                message: 'gleich gehts weiter...',
                            });
                            await loading.present();
                            await new Promise((resolve) => setTimeout(resolve, 300));
                            try {
                                coursesStore.cancelCourse(course.value.courseId);
                                await router.push({ name: Routes.PRACTICE_AREA });
                            } catch (error) {
                                console.error('hups', error);
                            } finally {
                                loading.dismiss();
                            }
                        },
                    },
                ],
            });
            alert.present();
        };

        const refreshPage = async () => {
            await userStore.pullLatestUserData();
            await updateCourseDataRoutine(courseId, true);
        };

        const hydrate = async () => {
            await updateCourseDataRoutine(courseId);
        };

        // this is the combination of "mounted" and "come back to existing (watcher)"
        onIonViewDidEnter(() => {
            hydrate();
        });

        onIonViewWillLeave(() => {
            // make sure video is paused. Else it keeps playing if you leave via router.
            if (introVideoPlayerRef.value !== null) {
                try {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    introVideoPlayerRef.value.pause();
                } catch (error) {
                    console.error(error);
                }
            }
        });

        return {
            course,
            refreshPage,
            cancelCourse,
            getAssetPath,
            goBackToOverview,
            goToExercises,
            courseIntroVideo,
            banOutline,
            ribbonOutline,
            introVideoPlayerRef,
            startNewCourse,
            isSkippedQuestions,
        };
    },
});
