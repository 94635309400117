
import {
    IonInput,
    IonRow,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonButton,
    toastController,
    loadingController,
    onIonViewDidEnter,
} from '@ionic/vue';
import { informationCircle, checkmarkCircle, arrowForwardCircle } from 'ionicons/icons';
import { defineComponent, Ref, ref } from 'vue';
import axios, { AxiosResponse } from 'axios';
import { useRouter } from 'vue-router';
import { Routes } from '@/router';
import { useUserStore } from '@/store/user';
import { useCoursesStore } from '@/store/courses';
import { getApiBaseUrl, requestResetPassword } from '@/services/Request.service';
import BasePageLayout from '@/components/BasePageLayout.vue';
import { IS_DEVELOPMENT_MODE } from '@/services/Environment.service';
import { getReadableServerMessage } from '@/services/Translations.service';
import { initPushNotifications } from '@/services/PushNotifications.service';
import { usePaymentStore } from '@/store/payment';

export default defineComponent({
    name: 'RegisterPage',
    components: {
        IonInput,
        IonItem,
        IonIcon,
        IonRow,
        IonLabel,
        IonPage,
        IonButton,
        BasePageLayout,
    },
    setup() {
        const router = useRouter();
        const userStore = useUserStore();
        const coursesStore = useCoursesStore();

        const email: Ref<string> = ref('');
        const password: Ref<string> = ref('');

        const login = async () => {
            if (email.value === '' || password.value === '') {
                showMessage({ message: 'Du musst E-Mail und Passwort ausfüllen.' });
                return;
            }

            const loading = await loadingController.create({
                message: 'Einen Moment noch!',
            });
            await loading.present();
            await new Promise((resolve) => setTimeout(resolve, 300));

            try {
                const response: AxiosResponse<any> = await axios.post(`${getApiBaseUrl()}/auth/local`, {
                    identifier: email.value,
                    password: password.value,
                });

                // Login was successful!
                // save user data to our store
                const { courses, ...relevantUserData } = response.data.user;
                userStore.addJwt(response.data.jwt);
                userStore.setUser(relevantUserData);

                // save courses data if they exist
                if (courses) {
                    await coursesStore.hydrateCourses(courses);
                }

                try {
                    const paymentStore = usePaymentStore();
                    await paymentStore.initPurchases(relevantUserData.uuid);
                } catch (error) {
                    console.error('fetching initial paymentStore data failed', error);
                }

                // go to start page
                router.push({ name: Routes.PRACTICE_AREA });

                // lets register for push notifications
                initPushNotifications();

                // inform user
                showMessage({
                    header: `Willkommen zurück ${relevantUserData.nickname}!`,
                    duration: 3000,
                    icon: checkmarkCircle,
                });
            } catch (error: any) {
                console.error(error);
                if (!error.response) {
                    showMessage({ message: 'Unbekannter Fehler' });
                    return;
                }
                const serverMessage = error.response.data.error.message;
                showMessage({ message: getReadableServerMessage(serverMessage) });
                return;
            } finally {
                await loading.dismiss();
            }
        };

        const navigateRegister = () => {
            router.push({ name: Routes.REGISTER });
        };

        const showMessage = async (overrides: any) => {
            const toast = await toastController.create({
                header: 'Hups, hier ist etwas schief gegangen!',
                icon: informationCircle,
                position: 'top',
                duration: 7000,
                buttons: [
                    {
                        text: 'OK',
                        role: 'cancel',
                    },
                ],
                ...overrides,
            });
            await toast.present();
        };

        onIonViewDidEnter(() => {
            // always reset fields
            email.value = '';
            password.value = '';

            if (IS_DEVELOPMENT_MODE) {
                email.value = process.env.VUE_APP_PREFILL_LOGIN_USERNAME
                    ? process.env.VUE_APP_PREFILL_LOGIN_USERNAME
                    : '';
                password.value = process.env.VUE_APP_PREFILL_LOGIN_PASSWORD
                    ? process.env.VUE_APP_PREFILL_LOGIN_PASSWORD
                    : '';
            }

            // inform user if coming from confirmation mail
            const accountConfirmation = Boolean(router.currentRoute.value.query.accountConfirmation);
            if (accountConfirmation) {
                showMessage({
                    header: 'Account bestätigt!',
                    message: 'Du kannst dich jetzt mit deiner E-Mail und deinem Passwort einloggen!',
                    icon: checkmarkCircle,
                });
            }
        });

        return {
            email,
            password,
            login,
            navigateRegister,
            requestResetPassword,
            arrowForwardCircle,
        };
    },
});
