import { Network } from '@capacitor/network';
import { toastController } from '@ionic/vue';
import { wifiOutline } from 'ionicons/icons';

let HAS_NETWORK_CONNECTION = true;

const addListeners = async () => {
    await Network.addListener('networkStatusChange', (status) => {
        HAS_NETWORK_CONNECTION = status.connected;
        console.log('network status changed to: ', HAS_NETWORK_CONNECTION);
    });
};

export const getNetworkStatusIsOnline = () => {
    return HAS_NETWORK_CONNECTION;
};

export const presentHasNoNetworkAlert = async () => {
    const toast = await toastController.create({
        header: 'Du hast gerade keine Internetverbindung.',
        message: 'Die App funktioniert offline leider nur eingeschränkt.',
        icon: wifiOutline,
        position: 'top',
        duration: 10000,
        buttons: [
            {
                text: 'OK',
                role: 'cancel',
            },
        ],
    });
    await toast.present();
};

export const initNetworkStatusWatcher = async () => {
    addListeners();
};
