
import {
    IonItem,
    IonLabel,
    IonPage,
    IonButton,
    IonIcon,
    IonRow,
    IonCheckbox,
    toastController,
    loadingController,
} from '@ionic/vue';
import { informationCircle, arrowForwardCircle } from 'ionicons/icons';
import { defineComponent, Ref, ref } from 'vue';
import axios, { AxiosResponse } from 'axios';
import { useRouter } from 'vue-router';

import { useUserStore } from '@/store/user';
import { Routes } from '@/router';
import { getApiBaseUrl } from '@/services/Request.service';
import { dayJsDe } from '@/services/Time.service';
import BasePageLayout from '@/components/BasePageLayout.vue';
import { getReadableServerMessage } from '@/services/Translations.service';

export default defineComponent({
    name: 'TestaccountPage',
    components: {
        IonItem,
        IonLabel,
        IonPage,
        IonButton,
        IonIcon,
        IonRow,
        IonCheckbox,
        BasePageLayout,
    },
    setup() {
        const router = useRouter();
        const userStore = useUserStore();

        const userWantsTestAccount = true;
        const email: Ref<string> = ref('');
        const password1: Ref<string> = ref('');
        const password2: Ref<string> = ref('');
        const checkedAgb: Ref<boolean> = ref(false);

        const register = async () => {
            // [#124] Use only year not full day anymore.
            const birthday = dayJsDe(`1900-01-01`, 'YYYY-MM-DD', true);
            if (!checkedAgb.value) {
                showMessage({ message: 'Die AGB müssen akzeptiert werden' });
                return;
            }

            const loading = await loadingController.create({
                message: 'Gleich kannst du loslegen...',
            });
            await loading.present();

            try {
                const response: AxiosResponse<any> = await axios.post(`${getApiBaseUrl()}/auth/local/register`, {
                    anonymousTestUser: true,
                    username: email.value,
                    email: email.value,
                    password: password1.value,
                    nickname: 'Anonym',
                    birthday: birthday.format('YYYY-MM-DD'), // [#124] Still stored as full date due to backwards compability in backend.
                    gender: 'd',
                });

                // if user is confirmend automatically (email verification is off)
                if (response.data.user.anonymousTestUser) {
                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    // this is used as mail and password for test users.
                    const testUserMail = response.data.user.email;

                    const loginResponse: AxiosResponse<any> = await axios.post(`${getApiBaseUrl()}/auth/local`, {
                        identifier: testUserMail,
                        password: testUserMail,
                    });

                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { courses, ...relevantUserData } = loginResponse.data.user;
                    userStore.addJwt(loginResponse.data.jwt);
                    userStore.setUser(relevantUserData);
                } else {
                    showMessage({
                        header: 'Fast geschafft!',
                        message:
                            'Dein Account wurde erfolgreich erstellt. Du musst deine Anmeldung jetzt noch bestätigen. Dazu haben wir dir eine E-Mail geschickt. Bitte klicke dort auf den Link zur Bestätigung und kommt dann zum Login zurück.',
                        duration: 0,
                        icon: informationCircle,
                    });
                }

                router.push('/start-new-course');
            } catch (error: any) {
                if (!error.response) {
                    showMessage({ header: 'Hups, hier ist etwas schief gegangen!', message: 'Unbekannter Fehler' });
                    return;
                }
                const serverMessage = error.response.data.error.message;
                showMessage({
                    header: 'Hups, hier ist etwas schief gegangen!',
                    message: getReadableServerMessage(serverMessage),
                    duration: 0,
                });
                return;
            } finally {
                await loading.dismiss();
            }
        };

        const navigateRegister = () => {
            router.push({ name: Routes.REGISTER });
        };

        const navigateLogin = () => {
            router.push({ name: Routes.LOGIN });
        };

        const showMessage = async (overrides: any) => {
            const toast = await toastController.create({
                message: 'Bitte Daten prüfen',
                icon: informationCircle,
                position: 'top',
                duration: 7000,
                buttons: [
                    {
                        text: 'OK',
                        role: 'cancel',
                    },
                ],
                ...overrides,
            });
            await toast.present();
        };

        return {
            userWantsTestAccount,
            email,
            password1,
            password2,
            checkedAgb,
            register,
            navigateRegister,
            navigateLogin,
            arrowForwardCircle,
        };
    },
});
