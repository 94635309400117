import router, { Routes } from '@/router';
import { useUserStore } from '@/store/user';
import { toastController, ToastOptions } from '@ionic/core';
import { loadingController } from '@ionic/vue';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { checkmarkCircle, informationCircle } from 'ionicons/icons';
import { getNetworkStatusIsOnline, presentHasNoNetworkAlert } from './Network.service';
import { showMessage } from './Notification.service';
import { getReadableServerMessage } from './Translations.service';

export const getAssetPath = (path: string) => {
    const testAbsolute = new RegExp('^(?:[a-z]+:)?//', 'i');
    const isAbsolute = testAbsolute.test(path);
    if (isAbsolute) {
        return path;
    }
    return `${assetBaseUrl()}${path}`;
};

const assetBaseUrl = () => {
    // default is just our base url (works when assets are hosted locally)
    return process.env.VUE_APP_BACKEND_BASE_URL;
};

export const getApiBaseUrl = () => {
    return `${process.env.VUE_APP_BACKEND_BASE_URL}/api`;
};

export const getAxiosRequestInstance = () => {
    const userStore = useUserStore();
    const axiosConfig: AxiosRequestConfig = {
        baseURL: getApiBaseUrl(),
        headers: {
            Authorization: `Bearer ${userStore.jwt}`,
        },
    };
    const instance = axios.create(axiosConfig);
    instance.interceptors.response.use(
        (response) => response,
        (error: AxiosError) => {
            if (error && error.response && error.response.status === 401) {
                // user token is invalid. logout and go to login page
                userStore.resetAll();
                presentInvalidTokenMessage({
                    message: 'Hin und wieder müssen wir uns versichern, dass du auch du bist. Bitte neu einloggen.',
                });
                router.push({ name: Routes.LOGIN });
                return Promise.reject({ handled: true });
            }
            if (error && error.response && error.response.status === 409) {
                // the local user object is outdated. No course update possible to avoid data loss. Log out.
                userStore.resetAll();
                presentInvalidTokenMessage({
                    message:
                        'Du hattest hier veraltete Daten gespeichert. Mit einem erneuten Login wird dieses Problem behoben.',
                });
                router.push({ name: Routes.LOGIN });
                return Promise.reject({ handled: true });
            }
            if (getNetworkStatusIsOnline() === false) {
                presentHasNoNetworkAlert();
                return Promise.reject({ handled: true });
            }
            return Promise.reject(error);
        }
    );
    return instance;
};

const presentInvalidTokenMessage = async (options: ToastOptions = {}) => {
    const toast = await toastController.create({
        header: 'Sorry! Du musst dich erneut einloggen.',
        icon: informationCircle,
        position: 'top',
        duration: 20000,
        buttons: [
            {
                text: 'OK',
                role: 'cancel',
            },
        ],
        ...options,
    });
    await toast.present();
};

export const requestResetPassword = async (email: string) => {
    if (email === '') {
        showMessage({ message: 'Du musst oben deine E-Mail angeben.' });
        return;
    }

    const loading = await loadingController.create({
        message: 'Einen Moment noch!',
    });
    await loading.present();
    await new Promise((resolve) => setTimeout(resolve, 300));

    try {
        await axios.post(`${getApiBaseUrl()}/auth/forgot-password`, {
            email: email,
        });

        // inform user
        showMessage({
            header: 'Prüfe jetzt deine E-Mails',
            message: 'Dort findest du einen Link um dein Passwort zu ändern.',
            icon: checkmarkCircle,
        });
    } catch (error: any) {
        console.error(error);
        if (!error.response) {
            showMessage({ message: 'Unbekannter Fehler' });
            return;
        }
        const serverMessage = error.response.data.error.message;
        showMessage({ message: getReadableServerMessage(serverMessage) });
        return;
    } finally {
        await loading.dismiss();
    }
};
