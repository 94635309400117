import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17458efc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "exercise-item__image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "exercise-item__description" }
const _hoisted_4 = {
  key: 0,
  class: "exercise-item__points ion-no-margin"
}
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "exercise-item__level"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    class: _normalizeClass(["exercise-item ion-no-padding ion-no-border", { 'exercise-item--locked': _ctx.locked }]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.locked ? () => {} : _ctx.clickAction()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.locked)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              icon: _ctx.lockClosedOutline,
              size: "small",
              class: "exercise-item__favorite"
            }, null, 8, ["icon"]))
          : (_ctx.score)
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 1,
                icon: _ctx.favorite ? _ctx.heart : _ctx.heartOutline,
                size: "small",
                class: "exercise-item__favorite",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.clickFavoriteAction()), ["stop"]))
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
        _createElementVNode("img", {
          slot: "start",
          src: _ctx.image
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.score > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createVNode(_component_ion_icon, {
                icon: _ctx.checkmarkCircleOutline,
                size: "small",
                color: "success",
                class: "exercise-item__badge"
              }, null, 8, ["icon"]),
              _createElementVNode("span", _hoisted_5, [
                _createElementVNode("span", null, "Feedback-Punkte: " + _toDisplayString(_ctx.score), 1),
                (_ctx.score > 0 && _ctx.level > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, " | "))
                  : _createCommentVNode("", true),
                (_ctx.level > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Stufe " + _toDisplayString(_ctx.level), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}