
import {
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonButton,
    IonIcon,
    IonRow,
    IonCheckbox,
    toastController,
    loadingController,
    IonSelect,
    IonSelectOption,
} from '@ionic/vue';
import { informationCircle, checkmarkCircle, arrowForwardCircle } from 'ionicons/icons';
import { defineComponent, Ref, ref } from 'vue';
import axios, { AxiosResponse } from 'axios';
import { useRouter } from 'vue-router';

import { useUserStore } from '@/store/user';
import { Routes } from '@/router';
import { getApiBaseUrl } from '@/services/Request.service';
import { dayJsDe } from '@/services/Time.service';
import BasePageLayout from '@/components/BasePageLayout.vue';
import { getReadableServerMessage } from '@/services/Translations.service';

export default defineComponent({
    name: 'RegisterPage',
    components: {
        IonInput,
        IonItem,
        IonLabel,
        IonPage,
        IonButton,
        IonIcon,
        IonRow,
        IonCheckbox,
        BasePageLayout,
        IonSelect,
        IonSelectOption,
    },
    setup() {
        const router = useRouter();
        const userStore = useUserStore();

        const email: Ref<string> = ref('');
        const username: Ref<string> = ref('');
        const password1: Ref<string> = ref('');
        const password2: Ref<string> = ref('');
        const birthdayYear: Ref<string> = ref('');
        const gender: Ref<string | null> = ref(null);
        const checkedAgb: Ref<boolean> = ref(false);
        const checkedNewsletter: Ref<boolean> = ref(false);

        const register = async () => {
            if (password1.value !== password2.value) {
                showMessage({ message: 'Die Passwörter stimmen nicht überein' });
                return;
            }

            if (!checkedAgb.value) {
                showMessage({ message: 'Die AGBs müssen akzeptiert werden' });
                return;
            }

            if (!birthdayYear.value) {
                showMessage({ message: 'Bitte Geburtsjahr auswählen' });
                return;
            }

            // [#124] Use only year not full day anymore.
            const birthday = dayJsDe(`${birthdayYear.value}-01-01`, 'YYYY-MM-DD', true);
            if (!birthday.isValid()) {
                showMessage({ message: 'Bitte Geburtsjahr prüfen. Es muss ein Jahr im Format "1990" sein' });
                return;
            }

            if (!gender.value) {
                showMessage({ message: 'Bitte Geschlecht auswählen' });
                return;
            }

            const loading = await loadingController.create({
                message: 'Dein Account wird gerade für dich erstellt...',
            });
            await loading.present();

            try {
                const params = checkedNewsletter.value ? '?newsletter=true' : '';
                const response: AxiosResponse<any> = await axios.post(
                    `${getApiBaseUrl()}/auth/local/register${params}`,
                    {
                        anonymousTestUser: false,
                        username: email.value,
                        email: email.value,
                        password: password1.value,
                        nickname: username.value,
                        birthday: birthday.format('YYYY-MM-DD'), // [#124] Still stored as full date due to backwards compability in backend.
                        gender: gender.value,
                    }
                );

                // if user is confirmend automatically (email verification is off)
                if (response.data.user && response.data.user.confirmed) {
                    // if email verifaction is activated confirmed will be false until user clicks the link in the mail
                    userStore.addJwt(response.data.jwt);

                    // save those data for later
                    userStore.setUser(response.data.user);

                    // inform user
                    showMessage({
                        header: 'Willkommen!',
                        message: 'Dein Account wurde erfolgreich erstellt.',
                        icon: checkmarkCircle,
                    });
                } else {
                    showMessage({
                        header: 'Fast geschafft!',
                        message:
                            'Dein Account wurde erfolgreich erstellt. Du musst deine Anmeldung jetzt noch bestätigen. Dazu haben wir dir eine E-Mail geschickt. Bitte klicke dort auf den Link zur Bestätigung und kommt dann zum Login zurück.',
                        duration: 0,
                        icon: informationCircle,
                    });
                }

                // go to start page (which then will forward to login page or wherever needed)
                router.push('/');
            } catch (error: any) {
                if (!error.response) {
                    showMessage({ header: 'Hups, hier ist etwas schief gegangen!', message: 'Unbekannter Fehler' });
                    return;
                }
                const serverMessage = error.response.data.error.message;
                showMessage({
                    header: 'Hups, hier ist etwas schief gegangen!',
                    message: getReadableServerMessage(serverMessage),
                    duration: 0,
                });
                return;
            } finally {
                await loading.dismiss();
            }
        };

        const navigateLogin = () => {
            router.push({ name: Routes.LOGIN });
        };

        const navigateTestaccount = () => {
            router.push({ name: Routes.TESTACCOUNT });
        };

        const showMessage = async (overrides: any) => {
            const toast = await toastController.create({
                message: 'Bitte Daten prüfen',
                icon: informationCircle,
                position: 'top',
                duration: 7000,
                buttons: [
                    {
                        text: 'OK',
                        role: 'cancel',
                    },
                ],
                ...overrides,
            });
            await toast.present();
        };

        return {
            email,
            username,
            password1,
            password2,
            checkedAgb,
            checkedNewsletter,
            birthdayYear,
            gender,
            register,
            navigateLogin,
            navigateTestaccount,
            arrowForwardCircle,
        };
    },
});
