
import { useUserStore } from '@/store/user';
import {
    IonPage,
    IonIcon,
    IonItemGroup,
    IonList,
    IonItem,
    IonLabel,
    IonListHeader,
    loadingController,
    IonToggle,
    alertController,
    IonButton,
} from '@ionic/vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Routes } from '@/router';
import BasePageLayout from '@/components/BasePageLayout.vue';
import BaseHeader from '@/components/BaseHeader.vue';
import { getReadableDate, getReadableYear } from '@/services/Time.service';
import {
    keyOutline,
    logOutOutline,
    earthOutline,
    mailOutline,
    shieldCheckmarkOutline,
    documentTextOutline,
    trashOutline,
    syncOutline,
    warningOutline,
    logoGooglePlaystore,
    logoAppleAppstore,
} from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { getAxiosRequestInstance } from '@/services/Request.service';
import version from '../version.json';
import { usePaymentStore } from '@/store/payment';
import { IS_DEVELOPMENT_MODE } from '@/services/Environment.service';
import { fetchAllCourses } from '@/services/Course.service';
import { CoursesCourseData } from '@/models/Courses.model';

export default defineComponent({
    name: 'ProfilePage',
    components: {
        IonPage,
        IonIcon,
        BasePageLayout,
        BaseHeader,
        IonItemGroup,
        IonList,
        IonItem,
        IonLabel,
        IonListHeader,
        IonToggle,
        IonButton,
    },
    setup() {
        const userStore = useUserStore();
        const user = computed(() => userStore.user);
        const router = useRouter();
        const isNativePlatform = Capacitor.isNativePlatform();
        const courseData = ref<Array<CoursesCourseData>>([]);

        const readableBirthday = computed(() => {
            if (user.value?.birthday) {
                return getReadableYear(user.value.birthday);
            }
            return '';
        });

        const readableGender = computed(() => {
            if (user.value?.gender) {
                if (user.value.gender === 'm') {
                    return 'männlich';
                }
                if (user.value.gender === 'f') {
                    return 'weiblich';
                }
                if (user.value.gender === 'd') {
                    return 'divers';
                }
            }
            return '';
        });

        const changePassword = async () => {
            router.push({ name: Routes.PASSWORD_RESET });
        };

        const respondToChangeEvent = ref(true);

        const updatePushNotifications = async (event: any) => {
            if (!respondToChangeEvent.value) {
                respondToChangeEvent.value = true;
                return;
            }

            if (!userStore.user) {
                return;
            }
            const loading = await loadingController.create({});
            const changeToValue = event.detail.checked;
            try {
                await loading.present();
                const axiosRequestInstance = getAxiosRequestInstance();
                await axiosRequestInstance.put('/update_me_base_data', {
                    allowPushNotifications: changeToValue,
                });
                userStore.setUser({
                    ...userStore.user,
                    allowPushNotifications: changeToValue,
                });
            } catch (error) {
                console.error('updatePushNotifications did fail', error);
                // manually reset value to old one if async call failed
                respondToChangeEvent.value = false;
                event.target.checked = !changeToValue;
            } finally {
                loading.dismiss();
            }
        };

        const logout = async () => {
            await userStore.resetAll();
            router.push({ name: Routes.LOGIN });
        };

        const deleteUserAccount = async () => {
            const alert = await alertController.create({
                header: 'Vorsicht!',
                message:
                    'Möchtest du deinen Account wirklich löschen? Dein Zugang, alle Kurse und deine Trainings-Fortschritte werden unwiderbringlich gelöscht.',
                buttons: [
                    {
                        text: 'Abbrechen',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Ja, jetzt meinen Account löschen',
                        id: 'confirm-button',
                        cssClass: 'danger-confirm-delete',
                        handler: async () => {
                            const loading = await loadingController.create({
                                message: 'gleich gehts weiter...',
                            });
                            await loading.present();
                            await new Promise((resolve) => setTimeout(resolve, 300));
                            try {
                                await userStore.deleteUserAccount();
                                await userStore.resetAll(false); // do not need to try remove device token because account got deleted above and handled this itself.
                                router.push({ name: Routes.REGISTER });
                            } catch (error) {
                                console.error('hups', error);
                            } finally {
                                loading.dismiss();
                            }
                        },
                    },
                ],
            });
            alert.present();
        };

        const completeUserAccount = () => {
            router.push({ name: Routes.COMPLETE_ACCOUNT });
        };

        const restorePurchases = async () => {
            const loading = await loadingController.create({ message: 'Deine In-App-Käufe werden wiederhergestellt' });

            try {
                await loading.present();
                await new Promise((resolve) => setTimeout(resolve, 300));
                await paymentStore.restorePurchases();

                const alert = await alertController.create({
                    header: 'Prozess abgeschlossen',
                    message: 'Die Anfrage wurde bearbeitet und eventuell vorhandenen Abos wurden wiederhergestellt',
                    buttons: [
                        {
                            text: 'Alles klar',
                            role: 'cancel',
                            cssClass: 'secondary',
                        },
                    ],
                });
                alert.present();
            } catch (error) {
                console.error(error);
            } finally {
                loading.dismiss();
            }
        };

        const hydrate = async () => {
            const loading = await loadingController.create({});
            try {
                await loading.present();

                await userStore.pullLatestUserData();

                const res = await fetchAllCourses();
                courseData.value = res.data.data;
            } catch (error) {
                console.error(error);
            } finally {
                loading.dismiss();
            }
        };

        onMounted(async () => {
            await hydrate();
        });

        // payment stuff
        const paymentStore = usePaymentStore();

        const availablePackages = computed(() => {
            return paymentStore.availablePackages;
        });

        const getCourseNameByEntitlement = (identifier: string) => {
            // first check if there is a RC package available to check the package description
            const foundPackage = availablePackages.value.find((item) => {
                return item.identifier === identifier;
            });
            if (foundPackage) {
                return foundPackage.serverDescription;
            }

            // else try to find an active course for the name (can be not available if the user has deleted the course)
            const foundCourse = courseData.value.find((item) => {
                return item.attributes.requiredEntitlement === identifier;
            });
            if (foundCourse) {
                return foundCourse.attributes.name;
            }

            return identifier;
        };

        const availableEntitlements = computed(() => {
            return paymentStore.entitlements.map((entitlement) => {
                return {
                    ...entitlement,
                    courseLabel: getCourseNameByEntitlement(entitlement.identifier),
                };
            });
        });

        const isAnonymousTestUser = computed(() => {
            return userStore.isAnonymousTestUser;
        });

        const initPurchases = async () => {
            if (user.value?.uuid) {
                paymentStore.initPurchases(user.value?.uuid);
            }
        };

        return {
            showDevTools: IS_DEVELOPMENT_MODE,
            user,
            isAnonymousTestUser,
            readableBirthday,
            readableGender,
            changePassword,
            logout,
            deleteUserAccount,
            hydrate,
            keyOutline,
            logOutOutline,
            earthOutline,
            shieldCheckmarkOutline,
            mailOutline,
            documentTextOutline,
            trashOutline,
            syncOutline,
            warningOutline,
            logoGooglePlaystore,
            logoAppleAppstore,
            isNativePlatform,
            updatePushNotifications,
            versionName: version.versionName,
            initPurchases,
            availablePackages,
            availableEntitlements,
            completeUserAccount,
            restorePurchases,
            getReadableDate,
            purchasePackage: paymentStore.purchasePackage,
        };
    },
});
