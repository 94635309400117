import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { getAxiosRequestInstance } from './Request.service';
import { showMessage } from './Notification.service';
import { Device } from '@capacitor/device';

const LOCALSTORE_DEVICE_TOKEN_KEY = 'deviceToken';

export const storeUserDeviceToken = async (token: string): Promise<void> => {
    const device = await Device.getInfo();
    const operatingSystem = device.operatingSystem;

    // check if token is already stored. Can happen for some reason that 'registration' event is called twice.
    // known issue but no one seems to care: https://github.com/ionic-team/capacitor/issues/1088
    const alreadyStoredToken = localStorage.getItem(LOCALSTORE_DEVICE_TOKEN_KEY);
    if (alreadyStoredToken === token) {
        console.info('device token got already stored. Do not save again to backend', token);
        return;
    }

    // first save to backend
    const axios = getAxiosRequestInstance();
    await axios.post('/user-device-tokens/', {
        data: {
            token,
            operatingSystem,
        },
    });

    // when successful save for later removal and to check if event was triggered already (above)
    localStorage.setItem(LOCALSTORE_DEVICE_TOKEN_KEY, token);
};

export const removeUserDeviceToken = async () => {
    const token = localStorage.getItem(LOCALSTORE_DEVICE_TOKEN_KEY);
    if (token) {
        const axios = getAxiosRequestInstance();
        await axios.post('/delete-user-device-token/', { data: { token } });
        localStorage.removeItem(LOCALSTORE_DEVICE_TOKEN_KEY);
    }
};

const addListeners = async () => {
    // make sure to clean up first
    await PushNotifications.removeAllListeners();

    await PushNotifications.addListener('registration', async (token) => {
        try {
            console.info('Registration token: ', token.value);
            await storeUserDeviceToken(token.value);
            // await showMessage({ message: 'Gerät für Push Notifications registriert' });
        } catch (error) {
            console.error(error);
            await showMessage({ message: 'Push Notifications: Registrierung fehlgeschlagen.' });
        }
    });

    await PushNotifications.addListener('registrationError', (err) => {
        console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
        // this is called when we receive notification while app is open.
        // IMHO this can be just ignored then for now :)
        console.log('Push notification received: ', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        // we get this information when user comes to app when tapped on notification.
        // TODO: redirect to courses page f.e. would be useful
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
};

const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
        // I think this happens when user somehow denied permissions on a phone level
        // TODO: could use this information to display some info, that user should disable this block?
        const errorMessage = 'User denied permissions!';
        console.error(errorMessage);
        throw new Error(errorMessage);
    }

    await PushNotifications.register();
};

// const getDeliveredNotifications = async () => {
//     const notificationList = await PushNotifications.getDeliveredNotifications();
//     console.log('delivered notifications', notificationList);
// };

export const initPushNotifications = async () => {
    if (Capacitor.isNativePlatform()) {
        await addListeners();
        await registerNotifications();
    } else {
        console.info('push notifications are not supported in web app');
    }
};
