import { ExerciseModel } from '@/models/Exercise.model';
import { ExerciseRatingCreateUpdateModel } from '@/models/ExerciseRating.model';
import { exerciseQuery } from '@/models/Exercise.query';
import { AxiosResponse } from 'axios';
import { getAxiosRequestInstance } from './Request.service';
import { getExerciseRatingsQuery } from '@/models/ExerciseRating.query';
import { LoadLevelExercise } from '@/models/Loadlevel.model';

export const getExercise = (id: number): Promise<AxiosResponse<ExerciseModel>> => {
    const axios = getAxiosRequestInstance();
    return axios.get(`/exercises/${id}?${exerciseQuery}`);
};

export const getExerciseRatings = (practiceArea: number, sort: Array<string>): Promise<AxiosResponse<any>> => {
    const axios = getAxiosRequestInstance();
    return axios.get(`/exercise-ratings/?${getExerciseRatingsQuery(practiceArea, sort)}`);
};

export const saveExerciseToBackend = (item: ExerciseRatingCreateUpdateModel): Promise<AxiosResponse<any>> => {
    const axios = getAxiosRequestInstance();
    return axios.post('/save-exercise-rating/', item);
};

export const getExerciseDisplayName = (item: LoadLevelExercise | ExerciseModel) => {
    if (!item.data) {
        return 'Ohne Titel';
    }
    return (
        item.data.attributes.displayName ||
        (item.data.attributes.baseExercise.data !== null
            ? item.data.attributes.baseExercise.data.attributes.displayName
            : 'Ohne Titel')
    );
};

export const getExerciseDescription = (item: ExerciseModel) => {
    if (item.data.attributes.descriptionOverride) {
        return item.data.attributes.descriptionOverride;
    }
    if (item.data.attributes.baseExercise.data) {
        return item.data.attributes.baseExercise.data.attributes.description || '';
    }
    return '';
};
