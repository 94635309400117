
import { CourseModelFullData } from '@/store/courses';
import { defineComponent, PropType } from 'vue';
import { IonProgressBar } from '@ionic/vue';
import { computed } from '@vue/reactivity';

export default defineComponent({
    name: 'CourseProgress',
    components: {
        IonProgressBar,
    },
    props: {
        course: {
            type: [Object, null] as PropType<CourseModelFullData | null>,
            required: true,
        },
    },
    setup(props) {
        const getProgressPercent = computed(() => {
            if (!props.course) {
                return 0;
            }
            return Math.floor((props.course.currentPoints / props.course.currentPointsToReach) * 100);
        });

        return {
            getProgressPercent,
        };
    },
});
