
import { defineComponent, ref } from 'vue';
import { IonIcon } from '@ionic/vue';
import { stopwatchOutline } from 'ionicons/icons';
import VueCountdown from '@chenfengyuan/vue-countdown';
import { useSound } from '@vueuse/sound';
import finishBellRing from '@/assets/sounds/finish-bell-ring.mp3';

export default defineComponent({
    name: 'ExerciseCountdown',
    components: {
        IonIcon,
        VueCountdown,
    },
    props: {
        timeInSeconds: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const counting = ref(false);

        const { play } = useSound(finishBellRing);

        const startCountdown = () => {
            counting.value = true;
        };

        const onCountdownEnd = () => {
            counting.value = false;
            play();
        };

        const readableTime = (totalSeconds: number) => {
            if (totalSeconds < props.timeInSeconds) {
                return totalSeconds + 1;
            }
            return totalSeconds;
        };

        return {
            counting,
            startCountdown,
            onCountdownEnd,
            readableTime,
            stopwatchOutline,
        };
    },
});
