import { defineStore } from 'pinia';
import { RemovableRef, StorageSerializers, useStorage } from '@vueuse/core';
import { useCoursesStore } from './courses';
import { getAxiosRequestInstance } from '@/services/Request.service';
import { AxiosResponse } from 'axios';
import { UserModel, UserRequestModel } from '@/models/User.model';
import { removeUserDeviceToken } from '@/services/PushNotifications.service';
import { useCourseData } from './courses/courseData';

import { alertController } from '@ionic/vue';
import { usePaymentStore } from './payment';
interface MainState {
    jwt: RemovableRef<string>;
    user: RemovableRef<UserModel | null>;
    initalPulledUserData: boolean;
    onboardingTourRequired: RemovableRef<boolean>;
}

export const useUserStore = defineStore({
    id: 'user',
    state: (): MainState => ({
        jwt: useStorage('jwt', ''),
        user: useStorage('user', null, undefined, { serializer: StorageSerializers.object }),
        initalPulledUserData: false,
        onboardingTourRequired: useStorage('onboardingTourRequired', true),
    }),
    getters: {
        isAuthenticated(): boolean {
            return !!this.jwt;
        },
        isAnonymousTestUser(): boolean {
            return !!this.user?.anonymousTestUser;
        },
        hasFullAccessToCourses(): boolean {
            return !!this.user?.userHasFullAccessToCourses;
        },
    },
    actions: {
        async pullLatestUserData() {
            try {
                const axios = getAxiosRequestInstance();
                const response: AxiosResponse<UserRequestModel> = await axios.get('/users/me');

                const { courses, ...relevantUserData } = response.data;

                // safe user data to store
                this.setUser(relevantUserData);

                // save courses data if they exist
                if (courses) {
                    const coursesStore = useCoursesStore();
                    await coursesStore.hydrateCourses(courses);
                }

                try {
                    const paymentStore = usePaymentStore();
                    await paymentStore.initPurchases(relevantUserData.uuid);
                } catch (error) {
                    console.error('fetching initial paymentStore data failed', error);
                }
            } catch (error) {
                console.error(error);
            } finally {
                // can always set this here, will only affect on initial load
                this.setInitialPulledUserData();
            }
        },
        addJwt(jwt: string) {
            this.jwt = jwt;
        },
        setUser(user: UserModel) {
            this.user = user;
        },
        async deleteUserAccount() {
            const axios = getAxiosRequestInstance();
            await axios.delete('/delete_me');
        },
        async resetAll(tryRemoveDeviceToken = true) {
            if (tryRemoveDeviceToken) {
                try {
                    // first delete connected user device tokens from backend
                    // there should be no push messages if user is not logged in
                    await removeUserDeviceToken();
                } catch (error) {
                    console.error('error while removing user device token from server. logged out anyways', error);
                }
            }

            // reset all user data in localstorage
            this.jwt = '';
            this.user = null;

            // courses
            const coursesStore = useCoursesStore();
            coursesStore.clearCourses();

            // courseData
            const courseDataStore = useCourseData();
            courseDataStore.wipeCourseData();
        },
        setInitialPulledUserData() {
            this.initalPulledUserData = true;
        },
        async onboardingTourDone() {
            this.onboardingTourRequired = false;
            const alert = await alertController.create({
                header: 'Du hast deine erste Übung absolviert',
                message:
                    '<b>Beachte bitte:</b><br/>Bewerte dich immer <i>angemessen</i>, so steigst du nicht zu früh auf, um dich nicht zu überlasten. Sei aber auch <i>nicht zu vorsichtig</i>, denn dein Körper wächst mit der Belastung.',
                buttons: [
                    {
                        text: 'Alles klar',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                ],
            });
            alert.present();
        },
    },
});
