import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-656fadd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_ctx.trainingIntervalAverage !== 0)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(_component_ion_icon, {
          class: "box-icon",
          icon: _ctx.barChartOutline,
          size: "large"
        }, null, 8, ["icon"]),
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(" Du machst " + _toDisplayString(_ctx.readableIntervalString !== 'jeden Tag' ? 'durchschnittlich alle' : '') + " ", 1),
          _createElementVNode("strong", null, _toDisplayString(_ctx.readableIntervalString), 1),
          _createTextVNode(" deine Übungen. "),
          _createElementVNode("div", {
            class: _normalizeClass(["box", `box--${_ctx.userInfoBox.color}`])
          }, [
            _createVNode(_component_ion_icon, {
              icon: _ctx.userInfoBox.icon,
              size: "medium"
            }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.userInfoBox.text), 1)
          ], 2)
        ])
      ], 64))
    : _createCommentVNode("", true)
}