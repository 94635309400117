import qs from 'qs';

export const coursesDataQuery = qs.stringify(
    {
        populate: {
            image: {
                fields: ['url'],
            },
            introSlides: {
                populate: {
                    graphic: {
                        fields: ['url'],
                    },
                },
            },
            introVideo: {
                fields: ['url', 'mime'],
            },
            introVideoPoster: {
                fields: ['url'],
            },
            loadlevels: {
                populate: {
                    fields: ['id'],
                },
            },
            teamMember: {
                populate: {
                    image: {
                        fields: ['url'],
                    },
                },
            },
        },
    },
    {
        encodeValuesOnly: true,
    }
);
