import { defineStore } from 'pinia';
import axios from 'axios';

import PurchasesNative, {
    PurchasesOfferings,
    CustomerInfo,
    PurchasesOffering,
    PurchasesPackage,
    LOG_LEVEL,
} from 'cordova-plugin-purchases/www/plugin';

import { debounce } from 'lodash';

import { getOperatingSystem, isNativePlatform } from '@/services/Standalone.service';
import { computed, ref } from 'vue';
import { useCourseData } from './courses/courseData';
import { useUserStore } from './user';
import { useCourseTrials } from './courses/courseTrials';
import { IS_DEVELOPMENT_MODE } from '@/services/Environment.service';
import { dayJsDe } from '@/services/Time.service';

interface MainState {
    customerInfo: CustomerInfo | null;
    offerings: PurchasesOfferings | null;
    customerInfoWeb: null | {
        entitlements: {
            [key: string]: {
                expires_date: string;
                grace_period_expires_date: null | string;
                product_identifier: string;
                purchase_date: string;
            };
        };
        subscriptions: {
            [key: string]: {
                auto_resume_date: null | string;
                billing_issues_detected_at: null | string;
                expires_date: string;
                grace_period_expires_date: null | string;
                is_sandbox: boolean;
                original_purchase_date: string;
                ownership_type: string;
                period_type: string;
                purchase_date: string;
                refunded_at: null | string;
                store: string;
                store_transaction_id: string;
                unsubscribe_detected_at: null | string;
                product_plan_identifier?: string;
            };
        };
    };
}

export const usePaymentStore = defineStore('payment', () => {
    const courseDataStore = useCourseData();
    const userStore = useUserStore();
    const courseTrials = useCourseTrials();

    const state = ref<MainState>({
        customerInfo: null,
        customerInfoWeb: null,
        offerings: null,
    });

    const availablePackages = computed((): Array<PurchasesOffering> => {
        const objToMap = state.value.offerings?.all;
        if (!objToMap) {
            return [];
        }
        return Object.keys(objToMap).map((key) => objToMap[key]);
    });

    const entitlements = computed(
        (): Array<{
            identifier: string;
            productId: string;
            store: string;
            purchaseDate: string;
            expirationDate: string | null;
        }> => {
            // native sdk entitlements
            if (isNativePlatform()) {
                const objToMap = state.value.customerInfo?.entitlements.active;
                if (!objToMap) {
                    return [];
                }
                return Object.keys(objToMap).map((key) => {
                    const item = objToMap[key];
                    return {
                        identifier: key,
                        productId: item.productIdentifier,
                        purchaseDate: item.latestPurchaseDate,
                        expirationDate: item.expirationDate,
                        store: item.store,
                    };
                });
            }

            // web entitlements
            const customerInfoWeb = state.value.customerInfoWeb;
            if (!customerInfoWeb) {
                return [];
            }
            return Object.keys(customerInfoWeb.entitlements)
                .map((key) => {
                    const item = customerInfoWeb.entitlements[key];
                    const foundSubcription = customerInfoWeb.subscriptions[item.product_identifier];

                    return {
                        identifier: key,
                        productId: item.product_identifier,
                        purchaseDate: item.purchase_date,
                        expirationDate: item.expires_date,
                        store: foundSubcription ? foundSubcription.store : '',
                    };
                })
                .filter((ent) => {
                    const isNotYetExpired = dayJsDe().isBefore(dayJsDe(ent.expirationDate));
                    return isNotYetExpired;
                    // return true || isNotYetExpired;
                });
        }
    );

    const userHasEntitlement = (entitlement: string | undefined) =>
        computed((): boolean => {
            if (!entitlement) {
                return false;
            }
            return entitlements.value.some((itemEnt) => itemEnt.identifier === entitlement);
        });

    const getCourseRequiredEntitlement = (courseId: number) =>
        computed(() => {
            const courseBaseData = courseDataStore.getCourseDataSynchron(courseId);
            return courseBaseData.value?.data.attributes.requiredEntitlement;
        });

    const getIsFreeCourse = (courseId: number | undefined) =>
        computed(() => {
            if (!courseId) {
                return false;
            }
            return !getCourseRequiredEntitlement(courseId).value;
        });

    const userCanUseCourse = (courseId: number | undefined) => {
        return computed(() => {
            if (!courseId) {
                return false;
            }

            // [#129] user has wildcard for all courses
            if (userStore.hasFullAccessToCourses) {
                return true;
            }

            // this is a free course
            if (getIsFreeCourse(courseId).value) {
                return true;
            }

            // this checks if the user has an active trial for this course
            if (courseTrials.userHasActiveTrialForCourse(courseId).value) {
                return true;
            }

            // last check is whether there is an active entitlement
            const requiredEntitlement = getCourseRequiredEntitlement(courseId);
            return userHasEntitlement(requiredEntitlement.value).value;
        });
    };

    const PUBLIC_GOOGLE_API_KEY = 'goog_TLICLhRnzTPVucYroIiCJyfSWDe';
    const PUBLIC_APPLE_API_KEY = 'appl_yiOasgsqEyCgbBvppSogqYCJtdd';

    const fetchCustomerDataWithWebApi = debounce(async (uuid: string) => {
        const options = {
            headers: { accept: 'application/json', authorization: `Bearer ${PUBLIC_GOOGLE_API_KEY}` },
        };

        try {
            const response = await axios.get(`https://api.revenuecat.com/v1/subscribers/${uuid}`, options);
            // test user with apple purchase history
            // const response = await axios.get(
            //     `https://api.revenuecat.com/v1/subscribers/feadf1c7-9c31-49ab-8e68-73968fac79c0`,
            //     options
            // );
            console.log(response.data.subscriber);
            state.value.customerInfoWeb = response.data.subscriber;
        } catch (err) {
            console.error(err);
        }
    }, 1000);

    const initPurchases = async (uuid: string | undefined) => {
        console.info('Start Initializing Purchases');

        if (!uuid) {
            console.warn('cannot init purchases. No user uuid passed.');
            return;
        }

        if (!isNativePlatform()) {
            console.info('Purchases Plugin does not work in web..., fetching user data with web api.');
            fetchCustomerDataWithWebApi(uuid);
            return;
        }

        PurchasesNative.setLogLevel(IS_DEVELOPMENT_MODE ? LOG_LEVEL.DEBUG : LOG_LEVEL.ERROR);

        const os = await getOperatingSystem();
        const apiKey = os === 'android' ? PUBLIC_GOOGLE_API_KEY : PUBLIC_APPLE_API_KEY;

        PurchasesNative.configureWith({
            apiKey,
            appUserID: uuid,
        });

        await loadCustomerInfo();
        await loadOfferings();

        console.info('Finished Initializing Purchases');
    };

    const restorePurchases = () => {
        return new Promise((resolve, reject) => {
            PurchasesNative.restorePurchases(
                (res) => {
                    console.log('restore purchases', res);
                    state.value.customerInfo = res;
                    resolve(res);
                },
                (err) => {
                    console.error('restore purchases', err);
                    reject(err);
                }
            );
        });
    };

    const loadOfferings = () => {
        return new Promise<PurchasesOfferings>((resolve, reject) => {
            PurchasesNative.getOfferings(
                (res) => {
                    console.log('offerings', res);
                    state.value.offerings = res;
                    resolve(res);
                },
                (err) => {
                    console.log('offerings err', err);
                    state.value.offerings = null;
                    reject(err);
                }
            );
        });
    };

    const loadCustomerInfo = () => {
        return new Promise<CustomerInfo>((resolve, reject) => {
            PurchasesNative.getCustomerInfo(
                (res) => {
                    console.log('customerInfo', res);
                    state.value.customerInfo = res;
                    resolve(res);
                },
                (err) => {
                    console.log('customerInfo err', err);
                    state.value.customerInfo = null;
                    reject(err);
                }
            );
        });
    };

    const purchasePackageNativePromise = (pkg: PurchasesPackage) => {
        return new Promise((resolve, reject) => {
            PurchasesNative.purchasePackage(
                pkg,
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    };

    const purchasePackage = async (pkg: PurchasesPackage) => {
        // TODO: need to ensure that we force user to (full register) when purchasing something later...

        try {
            await purchasePackageNativePromise(pkg);

            // do this after success to get latest info about user (new entitlements hopefully :))
            loadCustomerInfo();
        } catch (err: any) {
            if (err?.userCancelled) {
                // this is a soft error because user cancelled
            } else {
                // propagate error to caller to handle failed purchase
                console.error('error purchasePackage', err);
                throw err?.error || err;
            }
        }
    };

    return {
        initPurchases,
        purchasePackage,
        restorePurchases,
        availablePackages,
        entitlements,
        userHasEntitlement,
        userCanUseCourse,
        getCourseRequiredEntitlement,
        getIsFreeCourse,
    };
});
