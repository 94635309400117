
import { defineComponent, ref, Ref } from 'vue';
import BaseHeader from '@/components/BaseHeader.vue';
import {
    IonIcon,
    IonPage,
    IonModal,
    IonContent,
    modalController,
    loadingController,
    onIonViewDidEnter,
    IonAccordion,
    IonAccordionGroup,
    IonCard,
    IonCardContent,
    IonItem,
    IonLabel,
} from '@ionic/vue';
import BasePageLayout from '@/components/BasePageLayout.vue';
import {
    heart,
    heartOutline,
    optionsOutline,
    calendarOutline,
    lockClosedOutline,
    lockOpenOutline,
    ribbonOutline,
} from 'ionicons/icons';
import CourseAreaSwitcher from '@/components/CourseAreaSwitcher.vue';
import ArchiveExerciseItem from '@/components/ArchiveExerciseItem.vue';
import { useRouter } from 'vue-router';
import { Routes } from '@/router';
import { getAssetPath } from '@/services/Request.service';
import { getExerciseRatings, saveExerciseToBackend, getExerciseDisplayName } from '@/services/Exercise.service';
import { useCourseData } from '@/store/courses/courseData';
import { LoadLevelDataModel } from '@/models/Loadlevel.model';
import { useCoursesStore } from '@/store/courses';
import { useUserStore } from '@/store/user';

export default defineComponent({
    name: 'CourseArchivePage',
    components: {
        BasePageLayout,
        CourseAreaSwitcher,
        IonIcon,
        IonPage,
        BaseHeader,
        IonModal,
        IonContent,
        IonAccordion,
        IonAccordionGroup,
        IonLabel,
        ArchiveExerciseItem,
        IonCard,
        IonCardContent,
        IonItem,
    },
    setup() {
        const router = useRouter();
        const courseId = Number(router.currentRoute.value.params.id);
        const ratings: Ref<Array<any>> = ref([]);
        const allLevels: Ref<any> = ref(null);
        const coursesStore = useCoursesStore();
        const userStore = useUserStore();
        const currentCourse = coursesStore.getCourseById(courseId);

        type SortByOption = {
            id: number;
            label: string;
            sort: Array<string>;
        };

        const sortByModal: Ref<any> = ref(null);
        const sortByOptions: Array<SortByOption> = [
            {
                id: 0,
                label: 'Alle Belastungsstufen',
                sort: ['loadLevelNumber:desc', 'lastTimeDone:desc', 'updatedAt:desc'],
            },
            {
                id: 1,
                label: 'Zuletzt gemacht',
                sort: ['lastTimeDone:desc', 'updatedAt:desc'],
            },
            {
                id: 2,
                label: 'Favoriten',
                sort: ['favorite:desc', 'lastTimeDone:desc', 'updatedAt:desc'],
            },
            {
                id: 3,
                label: 'Punkte (absteigend)',
                sort: ['score:desc', 'favorite:desc', 'lastTimeDone:desc', 'updatedAt:desc'],
            },
            {
                id: 4,
                label: 'Punkte (aufsteigend)',
                sort: ['score:asc', 'favorite:desc', 'lastTimeDone:desc', 'updatedAt:desc'],
            },
        ];
        const activeSortByOption: Ref<SortByOption> = ref(sortByOptions[0]);

        const setActiveSortByOption = async (item: SortByOption) => {
            activeSortByOption.value = item;
            await modalController.dismiss();
            await hydrate();
        };

        const goToExercise = (exerciseId: number, loadLevelNumber: number, oldScore: number) => {
            router.push({
                name: Routes.PRACTICE_AREA_COURSE_PLAN_ARCHIVE_EXERCISE,
                params: { id: courseId, exercise: exerciseId },
                query: { oldScore, loadLevelNumber },
            });
        };

        const goBackToOverview = () => {
            router.push({ name: Routes.PRACTICE_AREA });
        };

        const favoriteClick = async (exerciseId: number, newState: boolean) => {
            await saveExerciseToBackend({
                practiceArea: courseId,
                exerciseId,
                favorite: newState,
            });
            await hydrate();
        };

        const courseDataStore = useCourseData();

        const arrayUniqueByKey = (list: Array<any>, key: string) => {
            return list.filter((v, i, a) => a.findIndex((v2) => v2[key] === v[key]) === i);
        };

        const flattenedGroupExercices = (
            loadlevel: LoadLevelDataModel,
            levelNumber: number,
            group: 'additionals' | 'basics'
        ) => {
            return loadlevel.data.attributes.days.flatMap((day, index) => {
                return day[group].flatMap((item) => {
                    if (item.practice.data === null) {
                        return [];
                    }

                    const rating = ratings.value.find(
                        (rating) => rating.attributes.exercise.data.id === item.practice.data?.id
                    );

                    const trainingDayOccurence = index + 1;

                    const canBeDone = () => {
                        // [#134] Superuser can view and do entire archive
                        if (userStore.hasFullAccessToCourses) {
                            return true;
                        }
                        // items with a rating can always be done
                        if (rating) {
                            return true;
                        }
                        if (currentCourse.value && currentCourse.value?.currentLevel > levelNumber) {
                            return true;
                        }
                        if (currentCourse.value && currentCourse.value?.currentLevel < levelNumber) {
                            return false;
                        }
                        // this means current level
                        if (currentCourse.value && currentCourse.value?.currentLevelDay > trainingDayOccurence) {
                            return true;
                        }
                        return false;
                    };

                    return {
                        id: item.practice.data.id,
                        data: item.practice.data,
                        trainingDayOccurence,
                        score: rating ? rating.attributes.score : 0,
                        favorite: rating ? rating.attributes.favorite : false,
                        canBeDone: canBeDone(),
                    };
                });
            });
        };

        const hydrate = async (forceServerData = false) => {
            const loading = await loadingController.create({});
            try {
                await loading.present();
                const res = await getExerciseRatings(courseId, activeSortByOption.value.sort);
                ratings.value = res.data.data;
                // const exerciseDoneIds = ratings.value.map((item) => item.attributes.exercise.data.id);

                // special case for load levels overview (all levels)
                if (activeSortByOption.value.id === 0) {
                    const course = await courseDataStore.getCourseData(courseId, forceServerData);

                    const allLoadLevelsPromises = course.data.attributes.loadlevels.map((item) => {
                        return courseDataStore.getLoadLevel(item.loadlevel.data.id, forceServerData);
                    });

                    const allLoadLevels = await Promise.all(allLoadLevelsPromises);

                    const bigPracticeList = allLoadLevels.map((loadlevel, index) => {
                        const levelNumber = index + 1;
                        const allBasics = flattenedGroupExercices(loadlevel, levelNumber, 'basics');

                        const allAdditionals = flattenedGroupExercices(loadlevel, levelNumber, 'additionals');
                        const allExercises = arrayUniqueByKey(
                            [...allBasics, ...allAdditionals].sort(
                                (a, b) => a.trainingDayOccurence - b.trainingDayOccurence
                            ),
                            'id'
                        );
                        const exerciseCount = allExercises.length;
                        const uncompletedItems = allExercises.filter((item) => item.score > 0);

                        const getLocked = () => {
                            // [#134] Superuser can view and do entire archive
                            if (userStore.hasFullAccessToCourses) {
                                return false;
                            }
                            return currentCourse.value
                                ? currentCourse.value?.currentLevel < levelNumber
                                    ? true
                                    : false
                                : false;
                        };

                        return {
                            level: levelNumber,
                            active: currentCourse.value?.currentLevel === levelNumber,
                            locked: getLocked(),
                            exerciseCount,
                            completenessPercent: Math.floor((uncompletedItems.length / exerciseCount) * 100),
                            exercises: allExercises,
                        };
                    });

                    allLevels.value = bigPracticeList;
                }
            } finally {
                await loading.dismiss();
            }
        };

        const refreshPage = () => {
            hydrate(true);
        };

        // this is the combination of "mounted" and "come back to existing (watcher)"
        onIonViewDidEnter(() => {
            hydrate();
        });

        return {
            sortByModal,
            heart,
            heartOutline,
            optionsOutline,
            calendarOutline,
            lockClosedOutline,
            lockOpenOutline,
            ribbonOutline,
            ratings,
            allLevels,
            sortByOptions,
            activeSortByOption,
            setActiveSortByOption,
            goToExercise,
            goBackToOverview,
            favoriteClick,
            modalController,
            getExerciseDisplayName,
            getAssetPath,
            refreshPage,
            currentCourse,
        };
    },
});
