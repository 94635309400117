import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ab3912a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "course-area-container" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "item__badge"
}
const _hoisted_5 = {
  key: 1,
  class: "item__badge item__badge--done"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabItems, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: _normalizeClass(["item headline-font", { 'item--active': _ctx.activeId === item.id }]),
          onClick: ($event: any) => (_ctx.clickItem(item.routeName))
        }, [
          _createTextVNode(_toDisplayString(item.label) + " ", 1),
          (item.badge && item.badge !== 'done')
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.badge), 1))
            : (item.badge)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _createVNode(_component_ion_icon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                ]))
              : _createCommentVNode("", true)
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}