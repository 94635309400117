import { computed } from 'vue';
import { defineStore } from 'pinia';
import { getAxiosRequestInstance } from '@/services/Request.service';
import { useUserStore } from '@/store/user';
import { AxiosResponse } from 'axios';
import { dayJsDe } from '@/services/Time.service';
import { usePaymentStore } from '../payment';

export type CoursesTrialData = {
    courseId: number;
    durationLabel: string;
    started: string;
    expires: string;
};

export const useCourseTrials = defineStore('courseTrials', () => {
    const userStore = useUserStore();
    const paymentStore = usePaymentStore();

    const startCourseTrial = (courseId: number): Promise<AxiosResponse<Array<CoursesTrialData>>> => {
        const axios = getAxiosRequestInstance();
        return axios.post('/start_course_trial', {
            courseId,
        });
    };

    const trialDataForCourse = (courseId: number) => {
        return computed(() => {
            // this checks if the user has already an trial for this course
            return userStore.user?.coursesTrialData?.find((item) => {
                return item.courseId === courseId;
            });
        });
    };

    const userCanStartTrialForCourse = (courseId: number | undefined) => {
        return computed(() => {
            if (!courseId) {
                return false;
            }
            return !trialDataForCourse(courseId).value;
        });
    };

    const userHasActiveTrialForCourse = (courseId: number | undefined) => {
        return computed(() => {
            if (!courseId) {
                return false;
            }
            const trialData = trialDataForCourse(courseId).value;
            if (trialData) {
                const trialIsActive = dayJsDe().isBefore(dayJsDe(trialData.expires));
                if (trialIsActive) {
                    return trialData;
                }
            }
            return false;
        });
    };

    const requestCourseTrial = async (courseId: number) => {
        // when its a free course do not start a trial since its free anyways
        // we can safely use synchron request here since in "addNewCourse" we forced a refresh already which is cached
        if (paymentStore.getIsFreeCourse(courseId).value) {
            return false;
        }

        // this checks if the user has already an active trial for this course
        const trialForThisCourse = trialDataForCourse(courseId);
        if (trialForThisCourse.value) {
            return false;
        }

        try {
            const trialStartResponse = await startCourseTrial(courseId);

            if (userStore.user) {
                // update local user object with new trial object from backend
                userStore.setUser({
                    ...userStore.user,
                    coursesTrialData: trialStartResponse.data,
                });
            }

            return trialStartResponse.data.find((item) => item.courseId === courseId);
        } catch (error) {
            console.error('could not start trial for course', error);
            return false;
        }
    };

    return {
        requestCourseTrial,
        userHasActiveTrialForCourse,
        userCanStartTrialForCourse,
    };
});
