
import { IonContent, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'BasePageLayout',
    components: {
        IonContent,
        IonRefresher,
        IonRefresherContent,
    },
    props: {
        contentFullscreen: {
            type: Boolean,
            defaults: false,
        },
        refreshFunction: {
            type: Function,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            default: () => {},
        },
    },
    emits: { refresh: null },
    setup(props) {
        const router = useRouter();

        const doRefresh = async ($event: any) => {
            try {
                await props.refreshFunction();
            } catch (error) {
                console.error('error refreshing page', error);
            } finally {
                $event.target.complete();
            }
        };

        const fullscreenView = computed(() => {
            // NOTE: can be used to hide header/footer. But causes ugly jumps
            return !!router.currentRoute.value.meta.fullscreen;
            // return false;
        });

        return {
            doRefresh,
            fullscreenView,
        };
    },
});
