import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_toolbar = _resolveComponent("page-toolbar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_version_info = _resolveComponent("version-info")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_page_toolbar)
        ]),
        _: 1
      }),
      _createVNode(_component_version_info, { ref: "versionInfoRef" }, null, 512)
    ]),
    _: 1
  }))
}