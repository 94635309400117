
import { CourseModelFullData } from '@/store/courses';
import { defineComponent, PropType } from 'vue';
import { IonIcon } from '@ionic/vue';
import { checkmarkOutline, barChartOutline, informationOutline, alertOutline } from 'ionicons/icons';
import { computed } from '@vue/reactivity';
import { dayJsDe } from '@/services/Time.service';

export default defineComponent({
    name: 'CourseAverageStats',
    components: {
        IonIcon,
    },
    props: {
        course: {
            type: Object as PropType<CourseModelFullData | null>,
            required: true,
        },
    },
    setup(props) {
        const DAY_COUNT_TO_RESPECT = 7;

        const trainingIntervalAverage = computed(() => {
            if (!props.course || props.course.trainingDays.length === 0) {
                return 0;
            }
            const limitedSortedDaysToCompare = [...props.course.trainingDays]
                .sort((a, b) => {
                    return b.localeCompare(a);
                })
                .slice(0, DAY_COUNT_TO_RESPECT);
            const dayToCompare = limitedSortedDaysToCompare[limitedSortedDaysToCompare.length - 1];
            const daysPassedSinceCompareDate =
                dayJsDe().endOf('day').diff(dayJsDe(dayToCompare).endOf('day'), 'days') + 1;
            return daysPassedSinceCompareDate / limitedSortedDaysToCompare.length;
        });

        const readableIntervalString = computed(() => {
            if (trainingIntervalAverage.value === 0) {
                return '...';
            }
            if (trainingIntervalAverage.value <= 1) {
                return 'jeden Tag';
            }
            return `${trainingIntervalAverage.value.toFixed(1)} Tage`;
        });

        const userInfoBox = computed(() => {
            if (trainingIntervalAverage.value < 2) {
                return {
                    icon: checkmarkOutline,
                    color: 'success',
                    text: 'Super! Du bist auf den richtigen Weg. Mache so weiter.',
                };
            }
            if (trainingIntervalAverage.value < 3) {
                return {
                    icon: checkmarkOutline,
                    color: 'success',
                    text: 'Das ist schon gut und vielleicht kannst du dich sogar noch weiter steigern',
                };
            }
            if (trainingIntervalAverage.value < 4) {
                return {
                    icon: informationOutline,
                    color: 'warning',
                    text: '​Ganz ordentlich! Aber da geht doch sicher noch mehr.',
                };
            }
            if (trainingIntervalAverage.value < 5) {
                return {
                    icon: informationOutline,
                    color: 'warning',
                    text: 'Bleib am Ball und steigere deine Frequenz um schneller voran zu kommen.',
                };
            }
            return {
                icon: alertOutline,
                color: 'danger',
                text: 'Denke immer daran. Je häufiger du trainierst, umso schneller dein Fortschritt.',
            };
        });

        return {
            trainingIntervalAverage,
            readableIntervalString,
            userInfoBox,
            checkmarkOutline,
            barChartOutline,
        };
    },
});
