import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return _withDirectives((_openBlock(), _createBlock(_component_ion_header, {
    translucent: true,
    class: "ion-no-border"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, {
        color: "primary",
        class: "main-toolbar"
      }, {
        default: _withCtx(() => [
          (_ctx.backButtonFunction)
            ? (_openBlock(), _createBlock(_component_ion_buttons, {
                key: 0,
                slot: "start"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.arrowBackOutline,
                    class: "back-button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backButtonFunction ? _ctx.backButtonFunction() : () => {}))
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_title, { class: "headline-font" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.pageTitle), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 512)), [
    [_vShow, !_ctx.fullscreenView]
  ])
}