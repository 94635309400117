import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { useUserStore } from '@/store/user';

import StartPage from '@/views/StartPage.vue';
import LoginPage from '@/views/LoginPage.vue';
import RegisterPage from '@/views/RegisterPage.vue';
import TestaccountPage from '@/views/TestaccountPage.vue';
import PasswordResetPage from '@/views/PasswordResetPage.vue';
import ProfilePage from '@/views/ProfilePage.vue';
import CompleteAccount from '@/views/CompleteAccount.vue';
import PracticeAreaPage from '@/views/PracticeAreaPage.vue';
import CourseDashboardPage from '@/views/CourseDashboardPage.vue';
import CoursePage from '@/views/CoursePage.vue';
import CourseArchivePage from '@/views/CourseArchivePage.vue';
import ExercisePage from '@/views/ExercisePage.vue';
import StartNewCourse from '@/views/StartNewCourse.vue';
import { loadingController } from '@ionic/vue';
import { useCoursesStore } from '@/store/courses';

export enum Routes {
    HOME = 'home',
    REGISTER = 'register',
    TESTACCOUNT = 'testaccount',
    LOGIN = 'login',
    START = 'start',
    PASSWORD_RESET = 'password-reset',
    PROFILE = 'profile',
    COMPLETE_ACCOUNT = 'complete-profile',
    PRACTICE_AREA = 'practice-area',
    PRACTICE_AREA_COURSE_PLAN = 'course',
    PRACTICE_AREA_COURSE_PLAN_ARCHIVE = 'course-archive',
    PRACTICE_AREA_COURSE_PLAN_ARCHIVE_EXERCISE = 'course-archive-exercise',
    PRACTICE_AREA_COURSE_PLAN_DASHBOARD = 'course-dashboard',
    PRACTICE_AREA_EXERCISE = 'exercise',
    START_NEW_COURSE = 'start-new-course',
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: Routes.HOME,
        redirect: () => {
            // [#140] show start page one single time on first app usage and later always go to login page
            const localStorageKey = 'startScreen';
            const showedValue = 'showed';
            if (localStorage.getItem(localStorageKey) !== showedValue) {
                localStorage.setItem(localStorageKey, showedValue);
                return { name: Routes.START };
            }
            return { name: Routes.LOGIN };
        },
    },
    {
        path: '/' + Routes.START,
        name: Routes.START,
        component: StartPage,
        meta: { title: 'Start', fullscreen: 'true' },
    },
    {
        path: '/' + Routes.LOGIN,
        name: Routes.LOGIN,
        component: LoginPage,
        meta: { title: 'Login', fullscreen: 'true' },
    },
    {
        path: '/' + Routes.REGISTER,
        name: Routes.REGISTER,
        component: RegisterPage,
        meta: { title: 'Registrieren', fullscreen: 'true' },
    },
    {
        path: '/' + Routes.TESTACCOUNT,
        name: Routes.TESTACCOUNT,
        component: TestaccountPage,
        meta: { title: 'Testen', fullscreen: 'true' },
    },
    {
        path: '/' + Routes.PASSWORD_RESET,
        name: Routes.PASSWORD_RESET,
        component: PasswordResetPage,
        meta: { title: 'Passwort zurücksetzen', fullscreen: 'true' },
    },
    {
        path: '/' + Routes.PROFILE,
        name: Routes.PROFILE,
        component: ProfilePage,
        meta: { title: 'Dein Profil' },
    },
    {
        path: '/' + Routes.COMPLETE_ACCOUNT,
        name: Routes.COMPLETE_ACCOUNT,
        component: CompleteAccount,
        meta: { title: 'Account vervollständigen' },
    },
    {
        path: '/' + Routes.PRACTICE_AREA,
        name: Routes.PRACTICE_AREA,
        component: PracticeAreaPage,
        meta: { title: 'Deine Kurse' },
    },
    {
        path: `/${Routes.PRACTICE_AREA}/:id/`,
        redirect: { name: Routes.PRACTICE_AREA_COURSE_PLAN_DASHBOARD },
    },
    {
        path: `/${Routes.PRACTICE_AREA}/:id/overview`,
        name: Routes.PRACTICE_AREA_COURSE_PLAN_DASHBOARD,
        component: CourseDashboardPage,
        meta: { title: 'Kurs Übersicht' },
    },
    {
        path: `/${Routes.PRACTICE_AREA}/:id/plan`,
        name: Routes.PRACTICE_AREA_COURSE_PLAN,
        component: CoursePage,
        meta: { title: 'Kurs' },
    },
    {
        path: `/${Routes.PRACTICE_AREA}/:id/:exercise`,
        name: Routes.PRACTICE_AREA_EXERCISE,
        component: ExercisePage,
        meta: { title: 'Übung', fullscreen: true },
    },
    {
        path: `/${Routes.PRACTICE_AREA}/:id/archive`,
        name: Routes.PRACTICE_AREA_COURSE_PLAN_ARCHIVE,
        component: CourseArchivePage,
        meta: { title: 'Archiv' },
    },
    {
        path: `/${Routes.PRACTICE_AREA}/:id/archive/:exercise`,
        name: Routes.PRACTICE_AREA_COURSE_PLAN_ARCHIVE_EXERCISE,
        component: ExercisePage,
        meta: { title: 'Übung (Archiv)', fullscreen: true },
    },
    {
        path: '/' + Routes.START_NEW_COURSE,
        name: Routes.START_NEW_COURSE,
        component: StartNewCourse,
        meta: { title: 'Neuer Kurs', fullscreen: true },
    },
    {
        // catch all path: "*". every not found route will be redirected to homepage
        // this is kind of our "404 handling". A 404 page is not neccissary in an app imo.
        path: '/:catchAll(.*)',
        name: 'NotFound',
        redirect: { name: Routes.HOME },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();
    const coursesStore = useCoursesStore();

    // make sure to load fresh user data once when opening the app to prevent old data
    if (!userStore.initalPulledUserData) {
        const loading = await loadingController.create({});
        try {
            await loading.present();
            await userStore.pullLatestUserData();
        } catch (error) {
            console.error('initial data load failed');
        } finally {
            loading.dismiss();
        }
    }

    if (
        to.name !== Routes.HOME &&
        to.name !== Routes.START &&
        to.name !== Routes.LOGIN &&
        to.name !== Routes.REGISTER &&
        to.name !== Routes.TESTACCOUNT &&
        to.name !== Routes.PASSWORD_RESET &&
        !userStore.isAuthenticated
    ) {
        // all routes need auth expect login, start, password reset and register
        next({ name: Routes.HOME });
    } else if (
        (to.name === Routes.START ||
            to.name === Routes.LOGIN ||
            to.name === Routes.REGISTER ||
            to.name === Routes.TESTACCOUNT) &&
        userStore.isAuthenticated
    ) {
        // visiting those pages is pointless when auth is given
        next({ name: Routes.PRACTICE_AREA });
    } else if (
        to.name === Routes.PRACTICE_AREA_COURSE_PLAN ||
        to.name === Routes.PRACTICE_AREA_COURSE_PLAN_ARCHIVE ||
        to.name === Routes.PRACTICE_AREA_COURSE_PLAN_ARCHIVE_EXERCISE ||
        to.name === Routes.PRACTICE_AREA_COURSE_PLAN_DASHBOARD ||
        to.name === Routes.PRACTICE_AREA_EXERCISE
    ) {
        // user wants to access a specific course. Check if its allowed!
        const courseId = Number(to.params['id']);
        const course = coursesStore.getFullCourseDataById(courseId);

        if (course.value?.userCanUseCourse) {
            next();
        } else {
            console.warn('USER CAN _NOT_ access course', courseId);
            next({ name: Routes.PRACTICE_AREA });
        }
    } else {
        next();
    }
});

export default router;
