import qs from 'qs';

export const exerciseQuery = qs.stringify(
    {
        populate: {
            baseExercise: {
                fields: ['displayName', 'description'],
                populate: {
                    video: {
                        fields: ['url', 'mime'],
                    },
                    videoPoster: {
                        fields: ['url'],
                    },
                },
            },
        },
    },
    {
        encodeValuesOnly: true,
    }
);
